import { FLAGS, useFeatureValue } from '@komi-app/flags-sdk';

export function useShouldHideGoogleOptionBasedOnUserAgent(
  userAgent: string
): boolean {
  const userAgents = useFeatureValue(
    FLAGS.SB_945_HIDE_GOOGLE_EMBED_BROWSERS,
    []
  );

  return userAgents.some((ua) => userAgent.includes(ua));
}
