export type SigninMethod = 'Google' | 'Email';

export type SignupAttemptPayload = {
  email: string;
  method: SigninMethod; // TODO: use common type
  successful: boolean;
};

export type ProfileCreationAttemptPayload = {
  industry: string;
  successful: boolean;
  isMobile: boolean;
  isMobileOnboardingExperimentEnabled: boolean;
};

export type AccountActivationAttemptPayload = {
  successful: boolean;
  sendNumber: number | null;
  verificationStatus: CompletedVerificationState;
};

export type CompletedVerificationState =
  | 'success'
  | 'expired'
  | 'error'
  | 'invalid'
  | 'already-used';

export type AnalyticsSource = 'client' | 'talent' | 'auth-spa';

export enum SEGMENT_EVENT {
  SIGN_UP = 'Signup',
  LOGIN = 'Successfull Login',
  VIEW_HOME_PAGE = 'View home page',
  VIEW_ALL_CATEGORY_SCREEN = 'View all categories screen',
  VIEW_ALL_EXPERIENCES = 'View all experiences',
  VIEW_TALENT_PROFILE = 'View talent profile',
  VIEW_CATEGORY = 'View category',
  VIEW_EXPERIENCE = 'View experience',
  VIEW_PRODUCT_PAGE = 'View product page',
  CHECKOUT = 'Checkout',
  VIEW_CALENDAR = 'View calender',
  DATE_SELECTED = 'Dates selected',
  VIEW_BOOKING_SCREEN = 'View booking screen',
  CONFIRM_BOOKING = 'Confirm booking',
  VIEW_SEARCH_SCREEN = 'View Search Screen',
  SEARCH = 'Search',
  VIEW_SEARCH = 'View search screen',
  CONFIRM_TIP = 'Confirm tip',
  JOIN_EXPERIENCE = 'Join experience',
  SHARE_EXPERT = 'Share Expert',
  SHARE_EXPERIENCE = 'Share Experience',
  VIEW_BUSINESS_LANDING_PAGE = 'View business landing page',
  VIEW_TALENT_TIPS_PAGE = 'View talent tips page',
  VIEW_TALENT_FAQS_PAGE = 'View talent FAQs page',
  VIEW_TALENT_PROFILE_TIP = 'View Talent profile tip selection',
  VIEW_TIP_DURING_EXPERIENCE = 'View tip selection during experience',
  EXPERT_PROFILE_TIP_CONFIRMATION = 'Expert profile tip confirmation',
  CLICK_FIND_OUT_MORE = 'Click find out more',
  CLICK_YOUTUBE_ELEMENT = 'Play youtube stream',
  CLICK_PRODUCT_ELEMENT = 'Click product link',
  CLICK_SHOPIFY_PRODUCT_ELEMENT = 'Click Shopify product element',
  VIEW_CHECKOUT_SHOPIFY_PRODUCT = 'View checkout page Shopify product',
  ORDER_COMPLETED_SHOPIFY_PRODUCT = 'Order completed Shopify product ',
  CLICK_CUSTOM_LINK = 'Click custom link',
  CLICK_BANDSINTOWN = 'Click bandintown link',
  CLICK_CUSTOM_EVENT = 'Click custom event link',
  CLICK_BANDSINTOWN_TICKET_LINK = 'Click Bandsintown ticket link',
  CLICK_BANDSINTOWN_RSVP_LINK = 'Click Bandsintown RSVP link',
  CLICK_CUSTOM_EVENT_TICKET_LINK = 'Click Custom Event ticket link',
  CLICK_EXPERIENCE_ELEMENT = 'View experience',
  CLICK_PREVIEW_MUSIC = 'Preview music',
  CLICK_PLAY_FULL_SONG = 'Click Play Full Song',
  CLICK_MUSIC_PROVIDER = 'Click music provider',
  CLICK_PRE_SAVE_PROVIDER = 'Click pre save provider',
  SUCCESSFULL_PRE_SAVE = 'Successfull pre-save',
  CLICK_PREVIEW_PODCAST = 'Preview podcast',
  CLICK_PLAY_FULL_PODCAST = 'Click Play Full Podcast',
  CLICK_PODCAST_PROVIDER = 'Click podcast provider',
  VIEW_LANDING_PAGE = 'View landing page',
  TALENT_REPORTED_BEFORE_CALL = 'Talent reported consumer before call',
  TALENT_KICK_DURING_CALL = 'Talent kicked consumer during call',
  SELFIE_DURING_CALL = 'Selfie during call',
  CLICK_SPECIAL_OFFER_COPY_CODE = 'Click Special Offer copy code',
  CLICK_LOGIN_BUTTON = 'Click Login Button',
  ACCESS_TALENT_PROFILE = 'Access Talent Profile',
  CLICK_JOIN_WAITLIST = ' Click Join Waitlist',
  JOINED_WAITLIST = 'Joined Waitlist',
  CLICK_INVITE_LINK = 'Self Sign Up - Click Invite Link',
  VIEW_TALENT_PROFILE_CREATION_SCREEN = 'Self Sign Up - View Talent Profile Creation Screen',
  TALENT_PROFILE_CREATED = 'Self Sign Up - Talent Profile Created',
  CLICK_DATA_CAPTURE_FORM = 'Click data capture form',
  SUBMIT_DATA_CAPTURE_FORM = 'Submit data capture form',
  CLICK_SHOP_MY_SHELF_PRODUCT = 'Click Shop My Shelf product',
  CLICK_SHOPLIST_PRODUCT = 'Click Shoplist product',
  CLICK_THE_SEATED_TICKET_LINK = 'Click the Seated Ticket link',
  CLICK_THE_SEATED_PROMOTED_LINK = 'Click the Seated Promoted link',
  CLICK_POWERED_BY_KOMI_BUTTON = 'Click Powered by Komi button',
  CLICK_LANDING_PAGE_CTA = 'Click Landing Page CTA',
  CLICK_CONTINUE_WITH = 'Click continue with',
  SIGN_UP_ATTEMPT = 'Sign up attempt',
  CLICK_RESEND_ACTIVATION_EMAIL = 'Click re-send activation email',
  ACCOUNT_ACTIVATION_ATTEMPT = 'Account activation attempt',
  PROFILE_CREATION_ATTEMPT = 'Profile creation attempt',

  ACCEPTED_COLLABORATION_INVITE = 'accepted_collaboration_invite',
  GA_CLICK_LANDING_PAGE_CTA = 'click_landing_page_cta',
  GA_CLICK_CONTINUE_WITH = 'click_continue_with',
  GA_SIGN_UP_ATTEMPT = 'sign_up_attempt',
  GA_CLICK_JOIN_WAITLIST = 'click_join_waitlist',
  GA_JOINED_WAITLIST = 'join_waitlist',
  GA_VIEW_LANDING_PAGE = 'view_landing_page',
  GA_VIEW_TALENT_PROFILE = 'view_talent_profile',
  GA_CLICK_POWERED_BY_KOMI_BUTTON = 'click_powered_by_komi_button',

  ADD_PRODUCT_TO_CART = 'Add product to cart',
  VIEW_CART = 'View cart',
  VIEW_SHIPPING_DETAILS = 'View shipping details',
  VIEW_SHIPPING_METHOD = 'View shipping method',
  VIEW_PAYMENT_DETAILS = 'View payment details',
  ORDER_CONFIRMED = 'Order confirmed',

  VIEW_ONBOARDING_PROFILE_SCREEN = 'View onboarding profile screen',
  COMPLETE_ONBOARDING_PROFILE_SCREEN = 'Complete onboarding profile screen',
  VIEW_ONBOARDING_INDUSTRY_SCREEN = 'View onboarding industry screen',
  COMPLETE_ONBOARDING_INDUSTRY_SCREEN = 'Complete onboarding industry screen',

  QUICK_ONBOARDING_LINK_IN_BIO_SELECTION = 'Quick onboarding link in bio selection',
  QUICK_ONBOARDING_ADD_MISSING_LINKS = 'Quick onboarding add missing links',
}

export type SegmentEventProperties = {
  [SEGMENT_EVENT.SIGN_UP]: Record<string, any>;
  [SEGMENT_EVENT.LOGIN]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_HOME_PAGE]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_ALL_CATEGORY_SCREEN]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_ALL_EXPERIENCES]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_TALENT_PROFILE]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_CATEGORY]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_EXPERIENCE]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_PRODUCT_PAGE]: {
    Currency: string;
    Price?: number;
    'Element order': number;
    'Element ID': string;
    'Module ID': string;
    'Commerce type': 'Shopify';
    'Talent Name': string;
    'Talent ID'?: number;
  };
  [SEGMENT_EVENT.CHECKOUT]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_CALENDAR]: Record<string, any>;
  [SEGMENT_EVENT.DATE_SELECTED]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_BOOKING_SCREEN]: Record<string, any>;
  [SEGMENT_EVENT.CONFIRM_BOOKING]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_SEARCH_SCREEN]: Record<string, any>;
  [SEGMENT_EVENT.SEARCH]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_SEARCH]: Record<string, any>;
  [SEGMENT_EVENT.CONFIRM_TIP]: Record<string, any>;
  [SEGMENT_EVENT.JOIN_EXPERIENCE]: Record<string, any>;
  [SEGMENT_EVENT.SHARE_EXPERT]: Record<string, any>;
  [SEGMENT_EVENT.SHARE_EXPERIENCE]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_BUSINESS_LANDING_PAGE]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_TALENT_TIPS_PAGE]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_TALENT_FAQS_PAGE]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_TALENT_PROFILE_TIP]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_TIP_DURING_EXPERIENCE]: Record<string, any>;
  [SEGMENT_EVENT.EXPERT_PROFILE_TIP_CONFIRMATION]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_FIND_OUT_MORE]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_YOUTUBE_ELEMENT]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_PRODUCT_ELEMENT]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_SHOPIFY_PRODUCT_ELEMENT]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_CHECKOUT_SHOPIFY_PRODUCT]: Record<string, any>;
  [SEGMENT_EVENT.ORDER_COMPLETED_SHOPIFY_PRODUCT]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_CUSTOM_LINK]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_BANDSINTOWN]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_CUSTOM_EVENT]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_BANDSINTOWN_TICKET_LINK]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_BANDSINTOWN_RSVP_LINK]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_CUSTOM_EVENT_TICKET_LINK]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_EXPERIENCE_ELEMENT]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_PREVIEW_MUSIC]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_PLAY_FULL_SONG]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_MUSIC_PROVIDER]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_PRE_SAVE_PROVIDER]: Record<string, any>;
  [SEGMENT_EVENT.SUCCESSFULL_PRE_SAVE]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_PREVIEW_PODCAST]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_PLAY_FULL_PODCAST]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_PODCAST_PROVIDER]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_LANDING_PAGE]: Record<string, any>;
  [SEGMENT_EVENT.TALENT_REPORTED_BEFORE_CALL]: Record<string, any>;
  [SEGMENT_EVENT.TALENT_KICK_DURING_CALL]: Record<string, any>;
  [SEGMENT_EVENT.SELFIE_DURING_CALL]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_SPECIAL_OFFER_COPY_CODE]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_LOGIN_BUTTON]: Record<string, any>;
  [SEGMENT_EVENT.ACCESS_TALENT_PROFILE]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_JOIN_WAITLIST]: {
    Location?: string;
    Platform: string;
    Position: string;
  };
  [SEGMENT_EVENT.JOINED_WAITLIST]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_INVITE_LINK]: Record<string, any>;
  [SEGMENT_EVENT.VIEW_TALENT_PROFILE_CREATION_SCREEN]: Record<string, any>;
  [SEGMENT_EVENT.TALENT_PROFILE_CREATED]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_DATA_CAPTURE_FORM]: Record<string, any>;
  [SEGMENT_EVENT.SUBMIT_DATA_CAPTURE_FORM]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_SHOP_MY_SHELF_PRODUCT]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_SHOPLIST_PRODUCT]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_THE_SEATED_TICKET_LINK]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_THE_SEATED_PROMOTED_LINK]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_POWERED_BY_KOMI_BUTTON]: Record<string, any>;
  [SEGMENT_EVENT.CLICK_LANDING_PAGE_CTA]: {
    Location?: string;
    'Operating System'?: string;
    'Landing page name': string;
    'CTA position': string;
  };
  [SEGMENT_EVENT.CLICK_CONTINUE_WITH]: {
    Method: 'Google' | 'Email';
  };
  [SEGMENT_EVENT.SIGN_UP_ATTEMPT]: {
    'Is successful?': 'Yes' | 'No';
    Method: string;
    platform?: 'Responsive' | 'Web';
    version?: string;
  };
  [SEGMENT_EVENT.CLICK_RESEND_ACTIVATION_EMAIL]: {
    'Send number': number | null;
  };
  [SEGMENT_EVENT.ACCOUNT_ACTIVATION_ATTEMPT]: {
    'Is successful?': 'Yes' | 'No';
    'Verification status':
      | 'success'
      | 'already-used'
      | 'expired'
      | 'error'
      | 'invalid';
    'Send number': number | null;
  };
  [SEGMENT_EVENT.PROFILE_CREATION_ATTEMPT]: {
    'Is successful?': 'Yes' | 'No';
    Industry: string;
    isMobile?: boolean;
    isMobileOnboardingExperimentEnabled?: boolean;
  };

  [SEGMENT_EVENT.GA_CLICK_POWERED_BY_KOMI_BUTTON]: {
    is_ga_only: true;
    location?: string;
    platform?: 'Responsive' | 'Web';
    talent_name?: string;
    talent_id?: number;
    content?: string;
  };
  [SEGMENT_EVENT.GA_CLICK_LANDING_PAGE_CTA]: {
    is_ga_only: true;
    location?: string;
    operating_system?: string;
    landing_page_name: string;
    cta_position: string;
  };
  [SEGMENT_EVENT.GA_CLICK_CONTINUE_WITH]: {
    is_ga_only: true;
    method: 'Google' | 'Email';
  };
  [SEGMENT_EVENT.GA_SIGN_UP_ATTEMPT]: {
    is_ga_only: true;
    is_successful?: 'Yes' | 'No';
    method: string;
    email: string;
    platform?: 'Responsive' | 'Web';
  };
  [SEGMENT_EVENT.GA_CLICK_JOIN_WAITLIST]: {
    is_ga_only: true;
    location?: string;
    platform: string;
    position: string;
  };
  [SEGMENT_EVENT.GA_JOINED_WAITLIST]: {
    is_ga_only: true;
    location?: string;
    platform: string;
    position: string;
    waitlist_id?: string;
  };
  [SEGMENT_EVENT.GA_VIEW_LANDING_PAGE]: {
    is_ga_only: true;
    location?: string;
    platform: string;
  };
  [SEGMENT_EVENT.GA_VIEW_TALENT_PROFILE]: {
    is_ga_only: true;
    user_id?: number;
    name?: string;
    location?: string;
    talent_user_id?: number;
    talent_name?: string;
    platform: 'Responsive' | 'Web';
    page_id: string | null;
    page_name: string;
  };
  [SEGMENT_EVENT.ACCEPTED_COLLABORATION_INVITE]: {
    anonymousID: string;
    accepted_invite: boolean;
    location?: string;
    operating_system?: string;
    platform?: string;
    email?: string;
  };
  [SEGMENT_EVENT.ADD_PRODUCT_TO_CART]: {
    Currency: string;
    Price?: number;
    'Element order': number;
    'Added from': 'Profile' | 'Product page';
    'Element ID': string;
    'Module ID': string;
    'Commerce type': 'Shopify';
    'Talent Name': string;
    'Talent ID'?: number;
  };
  [SEGMENT_EVENT.VIEW_CART]: {
    Currency: string;
    'Cart value'?: number;
    'Products in cart': number;
    'Talent Name': string;
    'Talent ID'?: number;
  };
  [SEGMENT_EVENT.VIEW_SHIPPING_DETAILS]: {
    Currency: string;
    'Cart value'?: number;
    'Products in cart': number;
    'Talent Name': string;
    'Talent ID'?: number;
  };
  [SEGMENT_EVENT.VIEW_SHIPPING_METHOD]: {
    Currency: string;
    'Cart value'?: number;
    'Products in cart': number;
    'Talent Name': string;
    'Talent ID'?: number;
  };
  [SEGMENT_EVENT.VIEW_PAYMENT_DETAILS]: {
    Currency: string;
    'Cart value'?: number;
    'Products in cart': number;
    'Talent Name': string;
    'Talent ID'?: number;
  };
  [SEGMENT_EVENT.ORDER_CONFIRMED]: {
    Currency: string;
    'Cart value'?: number;
    'Products in cart': number;
    'Talent Name': string;
    'Talent ID'?: number;
  };
  [SEGMENT_EVENT.VIEW_ONBOARDING_PROFILE_SCREEN]: OnboardingDefaultProperties;
  [SEGMENT_EVENT.COMPLETE_ONBOARDING_PROFILE_SCREEN]: OnboardingDefaultProperties &
    OnboardingCompleteEventProperties;
  [SEGMENT_EVENT.VIEW_ONBOARDING_INDUSTRY_SCREEN]: OnboardingDefaultProperties;
  [SEGMENT_EVENT.COMPLETE_ONBOARDING_INDUSTRY_SCREEN]: OnboardingDefaultProperties &
    OnboardingCompleteEventProperties;
  [SEGMENT_EVENT.QUICK_ONBOARDING_LINK_IN_BIO_SELECTION]: QuickOnboardingLinkInBioSelectionProperties;
  [SEGMENT_EVENT.QUICK_ONBOARDING_ADD_MISSING_LINKS]: QuickOnboardingAddMissingLinksProperties;
};

type OnboardingDefaultProperties = {
  platform: 'Web' | 'Responsive';
};

type OnboardingCompleteEventProperties = {
  Status: 'success' | 'skipped';
};

type QuickOnboardingLinkInBioSelectionProperties = {
  'Instagram Handle'?: string | null;
  'Template Name'?: string | null;
  'Category Name'?: string | null;
  'Website Url'?: string | null;
};

type QuickOnboardingAddMissingLinksProperties =
  QuickOnboardingLinkInBioSelectionProperties & {
    'Number of additional links'?: number;
  };
