import { FLAGS, isOn } from '@komi-app/flags-sdk';
import { UAParser } from 'ua-parser-js';
import {
  AccountActivationAttemptPayload,
  AnalyticsSource,
  ProfileCreationAttemptPayload,
  SEGMENT_EVENT,
  SegmentEventProperties,
  SignupAttemptPayload,
} from './types';
import { getUTMParams } from './utils/utm';
import Cookies from 'js-cookie';
import { SegmentIntegrationsObject } from './types/segment';
import { FAN_ID } from './constants';
import { v4 as uuidv4 } from 'uuid';
import { TrackSignin, TrackSignup } from '../legacy';
import { isMobile } from 'react-device-detect';

// TODO: move to platform

export class Tracking {
  private async track<T extends SEGMENT_EVENT>(
    event: T,
    properties?: SegmentEventProperties[T],
    options?: {
      /** Defaults to "client" */
      source?: AnalyticsSource;
      /** Defaults to "all" */
      integrations?: 'all' | 'all-except-google' | 'only-google';
    }
  ): Promise<void> {
    const utmParams = getUTMParams();
    const fanIDCookie = Cookies.get(FAN_ID);
    const propertiesWithUTMParams = properties
      ? { ...utmParams, ...properties, fanIDCookie }
      : { ...utmParams, fanIDCookie };

    const windowOrIFrame = getWindowOrIframe(options?.source ?? 'client');

    return (
      (windowOrIFrame as any)?.analytics as SegmentAnalytics.AnalyticsJS
    )?.track(event, propertiesWithUTMParams, {
      integrations: getSegmentIntegrations(options?.integrations ?? 'all'),
    });
  }

  trackSignin(user: TrackSignin, method: 'Google' | 'Email') {
    return this.track(SEGMENT_EVENT.LOGIN, {
      'Is successfull?': 'TRUE',
      'Talent User Full Name': `${user?.firstName} ${user?.lastName}`,
      'Login method': method,
      // Location: Cookies.get(KOMI_USER_LOCATION), // TODO
      Platform: isMobile ? 'Responsive' : 'Web',
      'Talent User ID': user?.id,
    });
  }

  trackSignupAttempt({ email, method, successful }: SignupAttemptPayload) {
    const isPlatformPropertyEnabled = isOn(
      FLAGS.GS_231_ADD_PLATFORM_TRACKING_TO_SIGN_UP
    );
    const useQuickOnboarding = isOn(FLAGS.USE_QUICK_ONBOARDING);

    const properties = {
      isSuccessful: successful ? 'Yes' : 'No',
      method,
      ...(isPlatformPropertyEnabled && {
        platform: this.getPlatform(),
      }),
      ...(useQuickOnboarding && {
        version: 'V2',
      }),
    } as const;

    return Promise.all([
      this.track(
        SEGMENT_EVENT.SIGN_UP_ATTEMPT,
        {
          'Is successful?': properties.isSuccessful,
          Method: properties.method,
          platform: properties.platform,
          version: properties.version ?? 'V1',
        },
        { source: 'talent', integrations: 'all-except-google' }
      ),
      this.track(
        SEGMENT_EVENT.GA_SIGN_UP_ATTEMPT,
        {
          is_ga_only: true,
          is_successful: properties.isSuccessful,
          method: properties.method,
          email,
          platform: properties.platform,
        },
        { source: 'talent', integrations: 'only-google' }
      ),
    ]);
  }

  trackSignup(user: TrackSignup) {
    return this.track(SEGMENT_EVENT.SIGN_UP, {
      'User id': user.id,
      Name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      // Age: moment().diff(user.dateOfBirth, "years"),
      Gender: user?.gender?.toUpperCase(),
      'Registration Date': user?.createdAt,
    });
  }

  trackProfileCreationAttempt({
    industry,
    successful,
    isMobile,
  }: ProfileCreationAttemptPayload) {
    return this.track(
      SEGMENT_EVENT.PROFILE_CREATION_ATTEMPT,
      {
        'Is successful?': successful ? 'Yes' : 'No',
        Industry: industry,
        isMobile,
      },
      {
        source: 'auth-spa',
      }
    );
  }

  trackAccountActivationAttempt({
    successful,
    sendNumber,
    verificationStatus,
  }: AccountActivationAttemptPayload) {
    return this.track(
      SEGMENT_EVENT.ACCOUNT_ACTIVATION_ATTEMPT,
      {
        'Is successful?': successful ? 'Yes' : 'No',
        'Verification status': verificationStatus,
        'Send number': sendNumber,
      },
      {
        source: 'talent',
      }
    );
  }

  trackResendActivationEmailClick(sendNumber: number | null) {
    return this.track(
      SEGMENT_EVENT.CLICK_RESEND_ACTIVATION_EMAIL,
      {
        'Send number': sendNumber,
      },
      {
        source: 'talent',
      }
    );
  }

  trackAcceptedCollaborationInvite(email?: string) {
    return this.track(SEGMENT_EVENT.ACCEPTED_COLLABORATION_INVITE, {
      accepted_invite: true,
      email: email,
      anonymousID: uuidv4(),
    });
  }

  // Quick Onboarding link in bio next click
  trackQuickOnboardingLinkInBioNextClick(data: {
    instagramHandle?: string | null;
    templateName?: string | null;
    categoryName?: string | null;
    websiteUrl?: string | null;
  }) {
    return this.track(SEGMENT_EVENT.QUICK_ONBOARDING_LINK_IN_BIO_SELECTION, {
      'Instagram Handle': data.instagramHandle,
      'Template Name': data.templateName,
      'Category Name': data.categoryName,
      'Website Url': data.websiteUrl,
    });
  }

  // Quick Onboarding add missing links next click
  trackQuickOnboardingAddMissingLinksNextClick(data: {
    instagramHandle?: string | null;
    templateName?: string | null;
    categoryName?: string | null;
    websiteUrl?: string | null;
    numberOfAdditionalLinks?: number;
  }) {
    return this.track(SEGMENT_EVENT.QUICK_ONBOARDING_ADD_MISSING_LINKS, {
      'Instagram Handle': data.instagramHandle,
      'Template Name': data.templateName,
      'Category Name': data.categoryName,
      'Website Url': data.websiteUrl,
      'Number of additional links': data.numberOfAdditionalLinks,
    });
  }

  identify(
    userId: string | number,
    traits?: Record<string, any>,
    options?: {
      /** Defaults to "client" */
      source?: AnalyticsSource;
    }
  ): void {
    const windowOrIFrame = getWindowOrIframe(options?.source ?? 'client');

    return (
      (windowOrIFrame as any)?.analytics as SegmentAnalytics.AnalyticsJS
    )?.identify(String(userId), traits);
  }

  identifyTalentUser({ id, email }: { id?: number; email?: string }): void {
    if (!id) return;

    return this.identify(id, { email }, { source: 'talent' });
  }

  getPlatform(): 'Responsive' | 'Web' {
    if (isOn(FLAGS.GS_231_ADD_PLATFORM_TRACKING_TO_SIGN_UP)) {
      const uaParserResult = UAParser();
      const isMobile = uaParserResult.device.type === 'mobile';
      return isMobile ? 'Responsive' : 'Web';
    }

    return this.getIncorrectPlatform();
  }

  private getIncorrectPlatform(): 'Responsive' | 'Web' {
    const uaParserResult = UAParser();
    return uaParserResult.device.type === 'mobile' ? 'Web' : 'Responsive';
  }

  async addToDataLayer(event: string) {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({ event: event });
  }
}

export default Tracking;

function getWindowOrIframe(source: AnalyticsSource) {
  if (source === 'client' || source === 'auth-spa') {
    return window;
  }
  if (source === 'talent') {
    const talentPixel = document?.getElementById(
      'talent-pixel'
    ) as HTMLIFrameElement | null;
    const talentPixelWindow = talentPixel?.contentWindow;
    return talentPixelWindow;
  }
  return undefined;
}

function getSegmentIntegrations(
  presetName: 'all' | 'all-except-google' | 'only-google'
): SegmentIntegrationsObject | undefined {
  switch (presetName) {
    case 'only-google':
      return {
        All: false,
        'Google Analytics': true,
        'Google Analytics 4': true,
        'Google Analytics Web': true,
      };

    case 'all-except-google':
      return {
        All: true,
        'Google Analytics': false,
        'Google Analytics 4': false,
        'Google Analytics Web': false,
      };
    case 'all':
    default:
      return undefined;
  }
}
