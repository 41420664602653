import { ProfileTemplates, ZTemplateValidator } from '@komi-app/onboarding-sdk';
import useQuery, { getQuery } from './query';

interface OnboardingData {
  template: ProfileTemplates;
}

export function isValidTemplate(
  template: string
): template is ProfileTemplates {
  return ZTemplateValidator.safeParse(template).success;
}

export function getOnboardingData(): OnboardingData | null {
  const { template } = getQuery();

  if (!template || !isValidTemplate(template)) {
    return null;
  }

  return { template: ZTemplateValidator.parse(template) };
}

export const useOnboardingData = (): OnboardingData | null => {
  const {
    query: { template },
  } = useQuery();

  if (!template || !isValidTemplate(template)) {
    return null;
  }

  return { template };
};

export default useOnboardingData;
