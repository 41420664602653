import { useEffect } from 'react';

import { useActionData, useLoaderData, useNavigate } from 'react-router-dom';

export type ActionRedirect<T = any> = {
  redirect: {
    path: string;
    state?: T;
  };
};

export const useRedirectData = <T>(): void => {
  const actionData = useActionData() as ActionRedirect<T>;
  const loaderData = useLoaderData() as ActionRedirect<T>;
  const navigate = useNavigate();

  useEffect(() => {
    const redirect = actionData?.redirect || loaderData?.redirect;

    if (redirect) {
      const { path, state } = redirect;

      navigate(path, { state });
    }
  }, [actionData, loaderData, navigate]);
};

export default useRedirectData;
