import '@komi-app/creator-ui/dist/style.css';
import './styles.scss';

import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import { createBrowserRouter, RouterProvider, Link } from 'react-router-dom';

import {
  LinkProps,
  // temporary feature for compat with komi-talent/komi-client
  setupLink,
  ToastContextProvider,
} from '@komi-app/creator-ui';

import { FeatureProvider } from '@komi-app/flags-sdk';
import { AuthTrackingProvider } from '@komi-app/analytics-sdk';

import { KetchProvider } from './context/ketch';
import { LogRocketProvider } from './context/logrocket';
import { UserAgentProvider } from './context/user-agent';

import { context } from './config';

import { KetchScript, KetchStyle } from './components/Ketch'; // FIXME: inject at html template level?

import {
  route as SigninRoute,
  alias as SigninAliasRoute,
} from './routes/Signin';
import { route as GoogleSigninRoute } from './routes/Signin/Google';
import {
  route as SignupRoute,
  alias as SignupAliasRoute,
} from './routes/Signup';
import { route as SignupEmailRoute } from './routes/Signup/Email';
import { route as ForgotPasswordRoute } from './routes/ForgotPassword';
import { route as ProfilesRoute } from './routes/Profiles';
import { route as ResetPasswordRoute } from './routes/ResetPassword';
import { route as InvitationRoute } from './routes/Invitation';
import { route as CreateProfileRoute } from './routes/CreateProfile';
import { route as CreateProfileIndustryRoute } from './routes/CreateProfile/Industry';
import { route as SignoutRoute } from './routes/Signout';
import {
  route as VerifyEmailRoute,
  alias as VerifyEmailAliasRoute,
} from './routes/Signup/VerifyEmail';
import { route as OnboardingMoreInfoRoute } from './routes/QuickOnboarding/MoreInfo';
import { route as OnboardingSocialLinksRoute } from './routes/QuickOnboarding/SocialLinks';

// TODO: fix so that debugs look better
// import ErrorPage from './ErrorPage';

setupLink((props: LinkProps) => {
  return <Link {...props} />;
});

const router = createBrowserRouter([
  SigninAliasRoute,
  SignupAliasRoute,
  VerifyEmailAliasRoute,
  SigninRoute,
  SignupRoute,
  SignupEmailRoute,
  ProfilesRoute,
  GoogleSigninRoute,
  ForgotPasswordRoute,
  ResetPasswordRoute,
  InvitationRoute,
  CreateProfileRoute,
  CreateProfileIndustryRoute,
  SignoutRoute,
  VerifyEmailRoute,
  OnboardingMoreInfoRoute,
  OnboardingSocialLinksRoute,
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <AuthTrackingProvider>
      <FeatureProvider context={context}>
        <KetchProvider>
          <LogRocketProvider>
            <UserAgentProvider>
              <ToastContextProvider>
                <KetchScript />
                <KetchStyle />
                <RouterProvider router={router} />
              </ToastContextProvider>
            </UserAgentProvider>
          </LogRocketProvider>
        </KetchProvider>
      </FeatureProvider>
    </AuthTrackingProvider>
  </StrictMode>
);
