import When from '@komi-app/when';
import { useEffect, useState } from 'react';
import {
  PreviewIFrame,
  PreviewProfile as ProfileBaseData,
} from '@komi-app/creator-ui';

export type PreviewProfile = ProfileBaseData & {
  previewId: string;
};

interface ProfilePreviewProps {
  profile: PreviewProfile;
}

const ProfilePreview = ({ profile }: ProfilePreviewProps) => {
  const [previewProfile, setPreviewProfile] = useState<PreviewProfile | null>(
    null
  );

  useEffect(() => {
    if (
      !profile ||
      !profile.profileInfo ||
      !profile.modules ||
      !profile.previewId
    )
      return;

    setPreviewProfile(profile);
  }, [profile]);

  useEffect(() => {
    function handleMessage(e: MessageEvent) {
      if (e.data.type === 'komi_claim_site') {
        window.location.href = `/signup?${e.data.params}`;
      }
    }

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <When
      value={!!previewProfile && !!previewProfile.previewId}
      then={
        <>
          <PreviewIFrame
            url={window.env.VITE_PROFILE_PREVIEW_URL}
            profile={profile}
            previewId={previewProfile?.previewId}
            className="preview-frame"
          />
        </>
      }
    />
  );
};

export default ProfilePreview;
