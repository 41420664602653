import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

type ObjectFromEntry = {
  [k: string]: string;
};

export function getQuery<T extends ObjectFromEntry>(): T {
  return Object.fromEntries(new URLSearchParams(location.search)) as T;
}

export const useQuery = <T extends ObjectFromEntry>() => {
  const [searchParams] = useSearchParams();
  const query = useMemo(
    () => Object.fromEntries(searchParams) as T,
    [searchParams]
  );

  return { query };
};

export default useQuery;
