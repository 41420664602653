import {
  ForgotPasswordPage,
  ToastCreationParameters,
} from '@komi-app/creator-ui';
import selfless from '@komi-app/selfless';

import {
  redirect,
  LoaderFunction,
  ActionFunction,
  Form,
} from 'react-router-dom';

import { legacy, isLoggedIn } from '../sdks';
import { LegacyForgotPasswordError } from '../errors/legacy';
import { useFormLoading } from '../hooks/form-loading';
import { useToastData } from '../hooks/toast-data';
import useLogRocket from '../hooks/logrocket';

type ForgotPasswordBody = {
  email: string;
};

export type ForgotPasswordViewProps = {
  disabled: boolean;
  loading: boolean;
};

export type ActionData = {
  toast: ToastCreationParameters;
};

export const loader: LoaderFunction = async () => {
  document.title = 'KOMI | Forgot Password?';

  if (await isLoggedIn()) {
    return redirect('/profiles');
  }

  return null;
};

export const action: ActionFunction = async ({ request }) => {
  const send = (payload: ForgotPasswordBody) => legacy.forgotPassword(payload);

  try {
    const payload = Object.fromEntries(
      await request.formData()
    ) as ForgotPasswordBody;

    await send(payload);

    return {
      toast: {
        text: 'Your password reset request has been sent. Check your email for further instructions.',
        semantic: 'success',
        durationMs: 30000,
      } as ToastCreationParameters,
    };
  } catch (e: any) {
    let message = 'Something went wrong';

    if (e instanceof LegacyForgotPasswordError) {
      const payload = await e.response.json();

      message = payload.message;
    }

    return {
      toast: {
        text: message,
        semantic: 'error',
        durationMs: 5000,
      },
    };
  }
};

export const useForgotPassword = (): ForgotPasswordViewProps => {
  useLogRocket();
  useToastData();
  const { loading } = useFormLoading();

  const disabled = false;

  return {
    disabled,
    loading,
  };
};

export const ForgotPasswordView = ({ loading }: ForgotPasswordViewProps) => (
  <Form method="POST">
    <ForgotPasswordPage
      shouldRemoveForm
      loading={loading}
      previousPageUrl="/signin" // TODO: condition al
    />
  </Form>
);

export const ForgotPassword = selfless(ForgotPasswordView, useForgotPassword);
export default ForgotPassword;

export const route = {
  path: '/forgot-password',
  element: <ForgotPassword />,
  loader,
  action,
};
