import { useEffect } from 'react';
import { useKetchPurposes } from '../context/ketch';
import {
  useLatestLogRocket,
  useLegacyLogRocket,
  logRocketIdentify,
} from '../context/logrocket';

import { FLAGS, useFeatureIsOn } from '@komi-app/flags-sdk';

import { getUser } from '../sdks/legacy';

export function useLogRocket() {
  const { initLogRocket } = useLegacyLogRocket();
  const { init, identify } = useLatestLogRocket();
  const user = getUser(); // TODO: auth context
  const purposes = useKetchPurposes();

  const isLogRocketFixOn = useFeatureIsOn(FLAGS.LOG_ROCKET_INIT);

  useEffect(() => {
    if (purposes.analytics) {
      if (isLogRocketFixOn) {
        initLogRocket();
        logRocketIdentify(user);
      } else {
        init();
        identify(user);
      }
    }
  }, [initLogRocket, init, identify, purposes, user]);
}

export default useLogRocket;
