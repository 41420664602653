import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import UAParser, { IResult } from 'ua-parser-js';

type UserAgentData = IResult & { isMobile: boolean };

const UserAgentContext = createContext<UserAgentData>(getUserAgentData());

function getUserAgentData(userAgentString?: string) {
  const uaParserResult = UAParser(userAgentString);
  return {
    ...uaParserResult,
    isMobile: uaParserResult.device.type === 'mobile',
  };
}

type IProps = {
  /** Only required when server-rendering */
  userAgentString?: string;
};

export const UserAgentProvider: FC<PropsWithChildren<IProps>> = ({
  children,
  userAgentString,
}) => {
  const [userAgentData, setUserAgentData] = useState(
    getUserAgentData(userAgentString)
  );
  useEffect(() => {
    setUserAgentData(getUserAgentData(userAgentString));
  }, [userAgentString]);
  return (
    <UserAgentContext.Provider value={userAgentData}>
      {children}
    </UserAgentContext.Provider>
  );
};

export function useUserAgentContext(): UserAgentData {
  return useContext(UserAgentContext);
}
