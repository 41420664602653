import { FLAGS, useFeatureIsOn } from '@komi-app/flags-sdk';
import { UserRoleType } from '@komi-app/auth-sdk';

import qs from 'query-string';
import { FaGoogle } from 'react-icons/fa';

import config from '../config';
import useQuery from './query';
import { getAuthSDK } from '../sdks';
import { useShouldHideGoogleOptionBasedOnUserAgent } from './google-option';

const successRedirectUrl = `${config.service.url}/signin/google`;

export function useSigninOptions(email?: string) {
  const { query } = useQuery();
  const useLegacy = useFeatureIsOn(FLAGS.USE_LEGACY_AUTH_API);
  const useApiGateway = useFeatureIsOn(FLAGS.USE_API_GATEWAY);
  const useGateway = !(useLegacy && !useApiGateway);

  const shouldHideGoogleOption = useShouldHideGoogleOptionBasedOnUserAgent(
    navigator.userAgent
  );

  const isCollaboratorInvitation = !!email;

  const onClickGoogle = !useGateway
    ? // komi-client://src/components/LoginGoogle
      () => {
        const redirect_uri =
          location.protocol === 'http:'
            ? useApiGateway
              ? `http://localhost/auth/v1/signin/google`
              : `http://localhost:${location.port}/signin/google`
            : `${config.service.url}/signin/google`;

        const signupProps = isCollaboratorInvitation
          ? {
              token: query.token,
              email: query.email,
              invitation: query.invitation,
              ...(query.newUser && { newUser: query.newUser }),
            }
          : {
              operation: UserRoleType.SELFSIGN, // obsolete?
              // newUser // TODO?
            };

        const loginParams = new URLSearchParams({
          prompt: 'select_account',
          response_type: 'token',
          scope:
            'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
          redirect_uri,
          client_id: config.google.oauthClientId,
          flowName: 'GeneralOAuthFlow',
          ...(email && { login_hint: email }),
          state: btoa(
            qs.stringifyUrl({
              url: window.location.href,
              query: {
                ...signupProps,
                login_google: true,
                // isCollaboratorInvitation || isTalentRegister || operation ? true : undefined,
              },
            })
          ),
        });

        const googleLoginUrl = new URL(
          `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?${loginParams.toString()}`
        );

        location.href = googleLoginUrl.toString();
      }
    : async () => {
        const { url } = await getAuthSDK().signinWithGoogle({
          successRedirectUrl,
          failureRedirectUrl: `${config.service.url}/signin?method=google&status=failure`,
          tracking: query,
        });

        window.location.href = new URL(url).href;
      };

  if (shouldHideGoogleOption) {
    return [];
  } else {
    return [
      {
        icon: <FaGoogle size={18} />,
        label: 'Sign in with Google',
        onClick: onClickGoogle,
      },
    ];
  }
}

export default useSigninOptions;
