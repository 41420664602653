import {
  Form,
  ActionFunction,
  redirect,
  LoaderFunction,
} from 'react-router-dom';

import {
  SigninPage,
  SigninOption,
  ToastCreationParameters,
} from '@komi-app/creator-ui';

import selfless from '@komi-app/selfless';
import { FLAGS, isOn } from '@komi-app/flags-sdk';
import { SigninBody } from '@komi-app/auth-sdk';

import {
  getAuthSDK,
  legacy,
  isLoggedIn,
  tracking,
  createTracker,
  AuthEvent,
} from '../sdks';
import config from '../config';

import { useEmailProcessor } from '../hooks/processors';
import useFormLoading from '../hooks/form-loading';
import useToastData, { urlToToast } from '../hooks/toast-data';

import { LegacySigninError } from '../errors/legacy';
import { useLogRocket } from '../hooks/logrocket';
import useSigninOptions from '../hooks/signin-options';

export interface SigninViewProps {
  emailProcessor: (value: string) => void;
  options: SigninOption[];
  loading: boolean;
}

export type ActionData = {
  toast: ToastCreationParameters;
};

const successRedirectUrl = `${config.service.url}/signin/google`;
const successfullLogin$ = createTracker(AuthEvent.SUCCESSFULL_LOGIN);

export const loader: LoaderFunction = async () => {
  document.title = 'KOMI | Sign in';

  const [toast, loggedIn] = await Promise.all([
    // tracking handled in toast fn
    urlToToast(),
    isLoggedIn(),
  ]);

  if (loggedIn) {
    return redirect('/profiles');
  }

  return {
    toast,
  };
};

export const action: ActionFunction = async ({ request }) => {
  const useLegacy = isOn(FLAGS.USE_LEGACY_AUTH_API);
  const useApiGateway = isOn(FLAGS.USE_API_GATEWAY);
  const useAnalyticsSDK = isOn(FLAGS.USE_ANALYTICS_SDK_AUTH);
  const auth = getAuthSDK();

  const useGateway = !(useLegacy && !useApiGateway);

  const signin = !useGateway
    ? (payload: SigninBody) => legacy.signin(payload)
    : (payload: SigninBody) => auth.signin(payload);

  try {
    const payload = Object.fromEntries(await request.formData()) as SigninBody;

    await signin(payload);

    // tracking
    if (useGateway) {
      auth.getUserDetails().then((user) =>
        useAnalyticsSDK
          ? successfullLogin$({
              method: 'Email',
              userId: user.legacyId,
              success: true,
              userFullname: `${user.firstName} ${user.lastName}`.trim(),
            })
          : tracking.trackSignin({ id: user.legacyId, ...user }, 'Email')
      );
    }

    return redirect(new URL(successRedirectUrl).pathname);
  } catch (e: any) {
    let notFound;

    if (e instanceof LegacySigninError) {
      const { status } = e.response;
      notFound = !useGateway
        ? status === 401 || status === 422
        : status === 404;
    }

    if (e?.status === 404) {
      notFound = true;
    }

    const message = notFound
      ? 'Invalid password, email not found, or signin with Google'
      : 'Something went wrong';

    return {
      toast: {
        text: message,
        semantic: 'error',
        durationMs: 5000,
      },
    };
  }
};

export const useSignin = (): SigninViewProps => {
  useLogRocket();
  useToastData();

  const { loading } = useFormLoading();
  const emailProcessor = useEmailProcessor();
  const options = useSigninOptions();

  return {
    options,
    emailProcessor,
    loading,
  };
};

export const SigninView = ({
  emailProcessor,
  options,
  loading,
}: SigninViewProps) => (
  <Form method="POST">
    <SigninPage
      shouldRemoveForm
      forgotPasswordUrl="/forgot-password"
      signupUrl="/signup"
      emailProcessor={emailProcessor}
      options={options}
      loading={loading}
    />
  </Form>
);

export const Signin = selfless(SigninView, useSignin);
export default Signin;

export const path = '/signin';

export const route = {
  path,
  element: <Signin />,
  loader,
  action,
};

export const alias = {
  path: '/login',
  loader: () => redirect(path),
};
