import Row from 'antd/lib/row';
import Spin from 'antd/lib/spin';

interface IProps {
  size?: 'large' | 'default' | 'small';
  showFull?: boolean;
}

const Loading = ({ size = 'default', showFull = false }: IProps) => {
  return (
    <Row
      className={showFull ? 'loading loading--full' : 'loading'}
      justify="center"
      align="middle"
    >
      <Spin size={size} />
    </Row>
  );
};

export default Loading;
