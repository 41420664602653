import selfless from '@komi-app/selfless';
import When from '@komi-app/when';
import Cookies from 'js-cookie';
import {
  LoaderFunction,
  json,
  redirect,
  useLoaderData,
  useNavigation,
} from 'react-router-dom';

import Loading from '../components/Loading';

import {
  // auth,
  isLoggedIn,
  getProfiles,
} from '../sdks';

import { LegacyProfile } from '../sdks/legacy';

import {
  Avatar,
  ClickProfileItemHandler,
  SelectProfileItemData,
  SelectProfile as SelectProfileView,
} from '@komi-app/creator-ui';
import useLogRocket from '../hooks/logrocket';
import { MouseEvent } from 'react';

export type ProfilesLoaderData = {
  success: boolean;
  data: { profiles: LegacyProfile[] };
};

const { protocol } = location;

export const goToDomainAdmin = (domain: string) => {
  const destination = protocol === 'http:' ? `${domain}:2000` : domain;

  return `${protocol}//${destination}/admin`;
};

export const setDirtyProfileCookie = (id: string) => {
  // DIRTY FIX: komi-talent profileId init, required for proper auth refresh

  Cookies.set('TALENT_PROFILE_ID', id, {
    domain: `.${location.hostname}`,
  });
};

export const loader: LoaderFunction = async () => {
  document.title = 'KOMI | Select profile';

  if (!(await isLoggedIn())) {
    return redirect('/signin');
  }

  const profiles = await getProfiles();

  // const { profiles: rawUserProfiles } = await auth.getBasicInfo();
  // // Don't show profiles without a displayName
  // const userProfiles: ProfileInfoForNavigation[] = []// rawUserProfiles.filter(({ displayName }) => displayName);

  if (profiles.length < 1) {
    return redirect('/create-profile');
  }

  if (profiles.length === 1 && profiles[0]) {
    const { id, domain } = profiles[0];

    setDirtyProfileCookie(id);

    return redirect(goToDomainAdmin(domain));
  }

  return json<ProfilesLoaderData>({ success: true, data: { profiles } });
};

export type ProfilesViewProps = {
  profiles: SelectProfileItemData[];
  isLoading: boolean;
  onClick: ClickProfileItemHandler;
};

export const useProfiles = (): ProfilesViewProps => {
  useLogRocket();

  const {
    data: { profiles },
  } = useLoaderData() as ProfilesLoaderData;
  const navigation = useNavigation();
  const isLoading = navigation.state !== 'idle';

  function onClick(e: MouseEvent, item: SelectProfileItemData) {
    e.preventDefault();
    setDirtyProfileCookie(item.id);

    location.href = item.url;
  }

  return {
    profiles: profiles.map(({ id, domain, name, avatar }) => ({
      id,
      details: domain,
      name,
      icon: <Avatar url={avatar} username={name} />,
      url: goToDomainAdmin(domain),
    })),
    onClick,
    isLoading,
  };
};

export const ProfilesView = ({
  profiles,
  isLoading,
  onClick,
}: ProfilesViewProps) => {
  return (
    <When
      value={isLoading}
      then={
        <>
          <div className="loading-overlay"></div>
          <Loading />
        </>
      }
      otherwise={
        <SelectProfileView
          profiles={profiles}
          onClickProfile={onClick}
          // TODO: role based controls
        />
      }
    />
  );
};

export const Profiles = selfless(ProfilesView, useProfiles);
export default Profiles;

export const route = {
  path: '/profiles',
  element: <Profiles />,
  loader,
};
