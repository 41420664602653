import qs from 'query-string';

import { TrackedSignupParams } from '../sdks/legacy';
import useQuery from '../hooks/query';

const formatQueryString = (prefix: '?' | '&', queryString: string) =>
  queryString ? `${prefix}${queryString}` : '';

/**
 * Custom hook that extracts and formats signup-related query parameters.
 *
 * This hook uses the `useQuery` hook to obtain query parameters from the URL, and attempts to extract a referral code from cookies if not present in the query.
 * It constructs and returns formatted query strings for use in URLs.
 *
 * @typedef {Object} TrackedSignupParams
 * @property {string} [utm_source] - The source of the traffic (e.g., a search engine or newsletter).
 * @property {string} [utm_medium] - The medium of the traffic (e.g., email or CPC).
 * @property {string} [utm_campaign] - The name of the marketing campaign.
 * @property {string} [referring_user] - The ID of the referring user.
 * @property {string} [referral_type] - The type of referral.
 * @property {string} [productId] - The ID of the product.
 * @property {string} [ucc] - The referral code from cookies.
 *
 * @returns {Object} - An object containing formatted query strings.
 * @returns {string} firstQuery - The query string starting with `?`.
 * @returns {string} lastQuery - The query string starting with `&`.
 *
 * @example
 * const { firstQuery, lastQuery } = useSignupQuery();
 * console.log(firstQuery); // Outputs: '?utm_source=...&utm_medium=...&utm_campaign=...&referring_user=...&referral_type=...&productId=...&ucc=...'
 * console.log(lastQuery);  // Outputs: '&utm_source=...&utm_medium=...&utm_campaign=...&referring_user=...&referral_type=...&productId=...&ucc=...'
 */
export const useSignupQuery = (): {
  firstQuery: string;
  lastQuery: string;
  query: TrackedSignupParams;
} => {
  const { query }: { query: TrackedSignupParams } = useQuery();

  try {
    //extract the refferral code from cookies
    query.ucc = query.ucc || (window as any).CelloAttribution('getReferral');
  } catch (e) {}

  const queryString = qs.stringify(query);

  return {
    firstQuery: formatQueryString('?', queryString),
    lastQuery: formatQueryString('&', queryString),
    query,
  };
};
