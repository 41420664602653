/**
 *
 * NOTE: Google auth redirection for komi-server
 *
 *  This has been ported straight from komi-client
 *  and will be removed as auth-service takes over
 *
 */

import { LoaderFunction, redirect } from 'react-router-dom';
import config from '../../config';
import {
  AuthEvent,
  createTracker,
  legacy,
  getAuthSDK,
  tracking,
  useAnalyticsFlag,
  useGatewayFlag,
  getGatewayProfilesSDK,
  useQuickOnboardingFlag,
} from '../../sdks';
import { LegacySigninWithGoogleError } from '../../errors/legacy';
import { GlobalRoleNames, Maybe } from '@komi-app/auth-sdk';
import { ClaimPreviewProfileError } from '@komi-app/profiles-sdk';

const path = '/signin/google';
const successfullLogin$ = createTracker(AuthEvent.SUCCESSFULL_LOGIN);

export const loader: LoaderFunction = async () => {
  document.title = 'KOMI | Google sign in';

  if (window.location.hostname === 'localhost') {
    return (window.location.href = `${config.service.url}${location.pathname}${location.hash}`);
  }

  const [useGateway, useAnalyticsSDK, useQuickOnboarding] = await Promise.all([
    useGatewayFlag(),
    useAnalyticsFlag(),
    useQuickOnboardingFlag(),
  ]);

  const { access_token, operation, email, token, preview_id } =
    decodeRedirectStateFragment();

  if (useGateway) {
    const auth = getAuthSDK();
    const user = await auth.getUserDetails();

    const { globalRoles } = user.roles;
    const roles = Object.keys(globalRoles);

    const isInternal = [
      GlobalRoleNames.SUPER_ADMIN,
      GlobalRoleNames.SUPER_USER,
      GlobalRoleNames.EXTERNAL,
    ].some((role) => roles.includes(role));

    return isInternal ? adminRedirect() : userRedirect(email, token);
  }

  let user;

  try {
    user = await legacy.signinWithGoogle(access_token, operation);

    if (useQuickOnboarding && preview_id) {
      await getGatewayProfilesSDK().claimPreviewProfile({
        previewId: preview_id,
      });
    }
  } catch (e) {
    let message = 'Something went wrong';

    //Conflict on claim profile
    if (e instanceof ClaimPreviewProfileError && e.status === 409) {
      //Redirect to create profile page with params
      const payload = await e.response.json();
      const findHandle = payload.error.match(/"([^"]+)"/);

      return redirect(
        `/create-profile?user_full_name=${`${user?.firstName} ${user?.lastName}`.trim()}&user_handle=${
          findHandle && findHandle[1]
        }&preview_id=${preview_id}`
      );
    }

    if (e instanceof LegacySigninWithGoogleError) {
      const payload = await e.response.json();

      message =
        e.status === 404
          ? "There's no account for this email. Please signup to proceed."
          : payload.message;
    }

    const path = email ? '/invitation' : '/signin';

    const extra = email ? `&email=${encodeURI(email)}&token=${token}` : '';

    return redirect(
      `${path}?method=google&status=failure&message=${encodeURI(
        message
      )}${extra}`
    );
  }

  useAnalyticsSDK
    ? successfullLogin$({
        method: 'Google',
        success: true,
        userFullname: `${user.firstName} ${user.lastName}`.trim(),
        userId: user.id!,
      })
    : tracking.trackSignin(user, 'Google');

  if (
    user?.roles?.find(
      (role) => role.name === 'moderator' || role.name === 'admin'
    )
  ) {
    return adminRedirect();
  }

  return userRedirect(email, token);
};

const adminRedirect = () => {
  const adminUrl = new URL(config.service.url);

  adminUrl.host =
    location.protocol === 'http:'
      ? `admin.${adminUrl.hostname}:4000`
      : `admin.${adminUrl.hostname}`;

  window.location.href = adminUrl.toString();
};

const userRedirect = (email?: string, token?: string) => {
  const url = email
    ? `/invitation?email=${encodeURI(email)}&token=${token}`
    : '/profiles';

  return redirect(url);
};

const decodeRedirectStateFragment = () => {
  const fragment = window.location.hash.substring(1);
  const params = new URLSearchParams(fragment);

  let state: Maybe<string>;
  let stateUrl: Maybe<URL>;
  let access_token: Maybe<string>;
  let operation: Maybe<string>;
  let decodedFragment: Maybe<string>;
  let email: Maybe<string>;
  let token: Maybe<string>;

  if (params.has('state')) {
    state = atob(String(params.get('state')));
    stateUrl = new URL(state);

    const stateParams = new URLSearchParams(state);
    operation = state.includes('operation')
      ? (stateParams.get('operation') as string)
      : void 0;

    email = state.includes('email')
      ? (stateParams.get('email') as string)
      : void 0;

    token = state.includes('token')
      ? (stateParams.get('token') as string)
      : void 0;
  }

  access_token = params.get('access_token') || '';
  decodedFragment = params.toString();

  const preview_id = params.get('preview_id');

  return {
    state,
    stateUrl,
    access_token,
    operation,
    decodedFragment,
    email,
    token,
    preview_id,
  };
};

export const route = {
  path,
  loader,
};
