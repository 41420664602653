import { LoaderFunction, redirect } from 'react-router-dom';
import {
  getProfiles,
  isLoggedIn,
  legacy,
  tracking,
  useAnalyticsFlag,
  createTracker,
  AuthEvent,
} from '../../sdks';
import { goToDomainAdmin } from '../Profiles';
import { getQuery } from '../../hooks/query';
import { CompletedVerificationState } from '../../sdks/tracking/types';

const accountActivationAttempt$ = createTracker(
  AuthEvent.ACCOUNT_ACTIVATION_ATTEMPT
);

export const loader: LoaderFunction = async () => {
  document.title = 'KOMI | Verify email';

  const { code, v } = getQuery();

  const verificationCode = String(code);
  const activationCodeVersion = Number(v);

  if (verificationCode) {
    const [res, useAnalyticsSDK] = await Promise.all([
      // TODO: Support based on jwt expiry
      legacy.verifyEmail(verificationCode),
      useAnalyticsFlag(),
    ]);

    const responseMap: { [status: number]: CompletedVerificationState } = {
      200: 'success',
      202: 'already-used',
      401: 'expired',
      500: 'error',
      404: 'invalid',
    };

    const statusCode = Number(res && res.ok && res.status);
    const verificationStatus = responseMap[statusCode] || 'error';
    const success = statusCode >= 200 && statusCode < 300;

    useAnalyticsSDK
      ? accountActivationAttempt$(
          {
            success,
            sendNumber: activationCodeVersion,
            status: verificationStatus,
          },
          { source: 'talent' }
        )
      : tracking.trackAccountActivationAttempt({
          successful: success,
          verificationStatus: verificationStatus,
          sendNumber: activationCodeVersion,
        });
  }

  if (!(await isLoggedIn())) {
    return redirect(
      `/signin?status=success&message=${encodeURI(
        'Your email has been activated.'
      )}`
    );
  }

  const profiles = await getProfiles(); // TODO: profile provider to share profiles

  if (profiles.length < 1) {
    return redirect('/create-profile');
  }

  if (profiles.length === 1 && profiles[0]) {
    const { domain } = profiles[0];
    return redirect(goToDomainAdmin(domain));
  }

  return redirect('/profiles');
};

const path = '/signup/verify-email';

export const route = {
  path,
  loader,
};

export const alias = {
  path: '/sign-up/verify-email',
  loader: () => redirect(path),
};
