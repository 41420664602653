// Filthy feature flag magic
const displayKetchBannerCss = `
  #lanyard_root {
    visibility: visible;
  }
`;
// const hideKetchBannerCss = `
//   #lanyard_root {
//     visibility: hidden;
//   }
// `;

export const KetchStyle = () => (
  <>
    {/* <style>{hideKetchBannerCss}</style> */}
    <style>{displayKetchBannerCss}</style>
  </>
);

export default KetchStyle;
