import { SignupEmailPage, SignupEmailPageData } from '@komi-app/creator-ui';
import selfless from '@komi-app/selfless';
import When from '@komi-app/when';

import { redirect, LoaderFunction, useNavigate } from 'react-router-dom';

import ReCAPTCHA from 'react-google-recaptcha';
import { getGatewayProfilesSDK, isLoggedIn, tracking } from '../../sdks';
import { ValidationProcessor, useToast } from '@komi-app/creator-ui';
import { LegacyUser, TrackedSignupParams } from '../../sdks/legacy';
import { legacy, getAuthSDK } from '../../sdks';
import { LegacySignupError } from '../../errors/legacy';
import {
  useEmailProcessor,
  useFullnameProcessor,
  useFullnameWithHtmlTagValidationProcessor,
  usePasswordProcessor,
} from '../../hooks/processors';

import config from '../../config';
import { RefObject, createRef, useState, useCallback } from 'react';
import { getQuery } from '../../hooks/query';
import { FLAGS, useFeatureIsOn } from '@komi-app/flags-sdk';
import { Maybe } from '@komi-app/auth-sdk';

import {
  AuthEvent,
  addToDataLayer,
  identifyTalentUser,
  useAuthTracking,
} from '@komi-app/analytics-sdk';
import { ClaimPreviewProfileError } from '@komi-app/profiles-sdk';

export const loader: LoaderFunction = async () => {
  document.title = 'KOMI | Sign up with email';

  if (await isLoggedIn()) {
    return redirect('/profiles');
  }

  return null;
};

// Manage form submission with react for recaptcha

export interface SignupEmailViewProps {
  initialFullName?: string;
  fullnameProcessor: ValidationProcessor;
  emailProcessor: ValidationProcessor;
  passwordProcessor: ValidationProcessor;
  recaptchaRef: RefObject<any>;
  loading: boolean;
  onSubmit: (data: SignupEmailPageData) => void;
}

function useSignUpEmailValidator() {
  const emailProcessor = useEmailProcessor();

  return useCallback(
    async (value: string) => {
      const validationError = await emailProcessor(value);

      if (validationError) {
        return validationError;
      }

      try {
        const isEmailAlreadyRegistered = await legacy.isEmailAlreadyRegistered(
          value
        );

        if (isEmailAlreadyRegistered) {
          return 'Email is already registered';
        }
      } catch (e) {
        // ignore: we don't want to block the user from signing up
        // sign-up action has a check for existing email
      }
    },
    [emailProcessor]
  );
}

export function useSignupEmail() {
  const [loading, setLoading] = useState(false);
  const { createToast } = useToast();
  const navigate = useNavigate();

  const useLegacy = useFeatureIsOn(FLAGS.USE_LEGACY_AUTH_API);
  const useApiGateway = useFeatureIsOn(FLAGS.USE_API_GATEWAY);
  const useQuickOnboarding = useFeatureIsOn(FLAGS.USE_QUICK_ONBOARDING);

  const useGateway = !(useLegacy && !useApiGateway);

  const useAnalyticsSDK = useFeatureIsOn(FLAGS.USE_ANALYTICS_SDK_AUTH);

  const useHtmlTagValidator = useFeatureIsOn(
    FLAGS.FIX_SB_170_EMAIL_SANITISATION
  );
  // const addQueryParam = useCallback((key: string, value: string) => {
  //   // const queryParams = { ...router.query, [key]: value };
  //   // navigate({
  //   //   pathname: "/signup/email",
  //   //   query: queryParams,
  //   // });
  // }, []);

  const fullnameProcessor = useFullnameProcessor();
  const fullnameWithHtmlTagValidatorProcessor =
    useFullnameWithHtmlTagValidationProcessor();
  const emailProcessor = useSignUpEmailValidator();
  const passwordProcessor = usePasswordProcessor();
  const recaptchaRef = createRef() as RefObject<any>;
  const { createTracker } = useAuthTracking();

  const signUpAttempt$ = createTracker(AuthEvent.SIGN_UP_ATTEMPT);
  const signup$ = createTracker(AuthEvent.SIGNUP);

  let previewId: string;

  const onSubmit = async ({
    email,
    fullname,
    password,
  }: SignupEmailPageData) => {
    setLoading(true);
    let successful = false;
    let user: Maybe<LegacyUser>;

    try {
      const {
        productId,
        referral_type,
        referring_user,
        ucc,
        utm_campaign,
        utm_medium,
        utm_source,
        preview_id,
      } = getQuery() as TrackedSignupParams;

      //Store profileId globally so it can be referred if needed
      previewId = preview_id || '';

      const googleRecaptchaToken = await recaptchaRef?.current?.executeAsync();

      user = await legacy.signup({
        fullname,
        email,
        password,
        googleRecaptchaToken,
        productId,
        referral_type,
        referring_user,
        ucc,
        utm_campaign,
        utm_medium,
        utm_source,
      });

      useAnalyticsSDK
        ? identifyTalentUser(user)
        : tracking.identifyTalentUser(user);

      // // Trigger a route change to trigger Safari to offer to save password
      // if (isKeychainFixEnabled) {
      //   addQueryParam("signup", "true");
      // }

      if (useGateway) {
        await getAuthSDK().signin({
          email,
          password,
        });
      }

      if (useQuickOnboarding && preview_id) {
        await getGatewayProfilesSDK().claimPreviewProfile({
          previewId: preview_id,
        });
      }

      successful = true;
    } catch (e) {
      let message = 'Something went wrong';

      //Conflict on claim profile
      if (e instanceof ClaimPreviewProfileError && e.status === 409) {
        //Redirect to create profile page with params
        const payload = await e.response.json();
        //Extract handle from quoted string
        const findHandle = payload.error.match(/"([^"]+)"/);

        return navigate(
          `/create-profile?user_full_name=${fullname}&user_handle=${
            findHandle && findHandle[1]
          }&preview_id=${previewId}`
        );
      }

      if (e instanceof LegacySignupError) {
        const payload = await e.response.json();

        if (typeof payload.message === 'string') message = payload.message;
      }

      recaptchaRef?.current?.reset();

      setLoading(false);

      createToast({
        text: message,
        semantic: 'error',
        durationMs: 5000,
      });
    }

    useAnalyticsSDK
      ? signUpAttempt$({
          method: 'Email',
          success: true,
        })
      : tracking.trackSignupAttempt({
          email,
          method: 'Email',
          successful,
        });

    useAnalyticsSDK
      ? addToDataLayer('trial-signup')
      : tracking.addToDataLayer('trial-signup');

    if (user) {
      useAnalyticsSDK
        ? signup$({
            email: user.email!,
            userFullname: `${user.firstName} ${user.lastName}`.trim(),
            userId: user.id!,
            createdAt: user.createdAt,
          })
        : tracking.trackSignup(user);
    }

    if (successful) return navigate('/profiles');
  };

  let initialFullName = '';
  if (useQuickOnboarding) {
    const query = getQuery();
    if (query && query?.user_full_name) {
      initialFullName = query.user_full_name;
    }
  }

  return {
    initialFullName,
    fullnameProcessor: useHtmlTagValidator
      ? fullnameWithHtmlTagValidatorProcessor
      : fullnameProcessor,
    emailProcessor,
    passwordProcessor,
    loading,
    recaptchaRef,
    onSubmit,
  };
}

export const SignupEmailView = ({
  initialFullName,
  fullnameProcessor,
  emailProcessor,
  passwordProcessor,
  loading,
  recaptchaRef,
  onSubmit,
}: SignupEmailViewProps) => (
  <>
    <SignupEmailPage
      fullname={initialFullName}
      autoCompleteAutoCorrectOff
      previousPageUrl="/signup"
      fullnameProcessor={fullnameProcessor}
      emailProcessor={emailProcessor}
      passwordProcessor={passwordProcessor}
      onSubmit={onSubmit}
      loading={loading}
    />

    <When
      value={config.google.recaptchaV2SiteKey}
      then={
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={config.google.recaptchaV2SiteKey}
          onChange={() => {
            console.log('Recaptcha: onChange');
          }}
          onErrored={() => {
            console.log('Recaptcha: onErrored');
          }}
          onExpired={() => {
            console.log('Recaptcha: onExpired');
          }}
        />
      }
    />
  </>
);

export const SignupEmail = selfless(SignupEmailView, useSignupEmail);
export default SignupEmail;

export const route = {
  path: '/signup/email',
  element: <SignupEmail />,
  loader,
};
