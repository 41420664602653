import { createContext, useContext, useState } from 'react';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import config from '../config';
import { getCorrelationId } from '@komi-app/correlation';

export interface LogRocketFunctions {
  init: () => void;
  identify: (user?: any) => void;
}

const LogRocketContext = createContext<LogRocketFunctions | undefined>(
  undefined
);

export const LogRocketProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [inited, setInited] = useState(false);
  const [identified, setIdentified] = useState(false);

  const init = () => {
    if (typeof window !== 'undefined' && !inited) {
      LogRocket.init(config.logRocket.id! as string, {
        rootHostname: 'komi.io',
        console: {
          isEnabled: true,
        },
        dom: {
          privateAttributeBlocklist: ['data-no-track'], // can be used to hide elements from LogRocket
        },
        network: {
          requestSanitizer(request) {
            request.body = undefined;
            return request;
          },
        },
      });
      setupLogRocketReact(LogRocket);
      setInited(true);
    }
  };

  const identify = (user?: any) => {
    if (!identified) {
      const correlationId =
        sessionStorage.getItem('correlationId') || getCorrelationId();
      sessionStorage.setItem('correlationId', correlationId!);

      LogRocket.identify(
        correlationId!,
        user
          ? {
              // case where logged in but talendProfile is null?
              name: `${user.talentProfile?.firstName} ${user.talentProfile?.lastName}`,
              email: user?.email || '',
            }
          : undefined
      );
      setIdentified(true);
    }
  };

  return (
    <LogRocketContext.Provider value={{ init, identify }}>
      {children}
    </LogRocketContext.Provider>
  );
};

export const useLatestLogRocket = (): LogRocketFunctions => {
  const context = useContext(LogRocketContext);
  if (context === undefined) {
    throw new Error('useLogRocket must be used within a LogRocketProvider');
  }
  return context;
};

export const useLegacyLogRocket = () => {
  const initLogRocket = () => {
    if (typeof window !== 'undefined') {
      LogRocket.init(config.logRocket.id! as string, {
        rootHostname: 'komi.io',
        console: {
          isEnabled: false,
        },
        dom: {
          privateAttributeBlocklist: ['data-no-track'], // can be used to hide elements from LogRocket
        },
      });
      setupLogRocketReact(LogRocket);
    }
  };

  return { initLogRocket };
};

export function logRocketIdentify(user?: any): void {
  const correlationId =
    sessionStorage.getItem('correlationId') || getCorrelationId();
  sessionStorage.setItem('correlationId', correlationId!);

  LogRocket.identify(
    correlationId!,
    user
      ? {
          // case where logged in but talendProfile is null?
          name: `${user.talentProfile?.firstName} ${user.talentProfile?.lastName}`,
          email: user?.email || '',
        }
      : undefined
  );
}
