import Auth from '@komi-app/auth-sdk';
import Profiles from '@komi-app/profiles-sdk';
import { FLAGS, isOn } from '@komi-app/flags-sdk';
import { createAuthTracker, AuthEvent } from '@komi-app/analytics-sdk';

import config, { flags } from '../config';
import Legacy from './legacy';
import Tracking from './tracking';
import { JWT } from './jwt';
import { Onboarding } from '@komi-app/onboarding-sdk';

export { createAuthTracker as createTracker, AuthEvent };

let auth: Auth;
let profiles: Profiles;
let gatewayProfiles: Profiles;
let onboarding: Onboarding;

export const getAuthSDK = (): Auth => {
  if (!auth) {
    const useApiGateway = isOn(FLAGS.USE_API_GATEWAY);
    const authUrl = useApiGateway ? config.auth.gatewayUrl : config.auth.url;

    auth = new Auth({
      authUrl,
      targetUrl: authUrl,
      serviceName: config.service.name,
      serviceVersion: config.service.version,
    });
  }

  return auth;
};

export const legacy = new Legacy({
  targetUrl: config.legacy.url,
  serviceName: config.service.name,
  serviceVersion: config.service.version,
  authUrl: config.auth.url,
});

export const tracking = new Tracking();

export const getProfilesSDK = (): Profiles => {
  if (!profiles) {
    const useApiGateway = isOn(FLAGS.USE_API_GATEWAY);
    const authUrl = useApiGateway ? config.auth.gatewayUrl : config.auth.url;
    const targetUrl = useApiGateway
      ? config.profiles.gatewayUrl
      : config.profiles.url;

    profiles = new Profiles({
      authUrl,
      targetUrl,
      serviceName: config.service.name,
      serviceVersion: config.service.version,
    });
  }

  return profiles;
};

export const getGatewayProfilesSDK = (): Profiles => {
  if (!gatewayProfiles) {
    //FIXME: Force to use legacy auth & point to serverless profiles for now
    const authUrl = config.auth.url;
    const targetUrl = config.profiles.gatewayUrl;

    gatewayProfiles = new Profiles({
      authUrl,
      targetUrl,
      serviceName: config.service.name,
      serviceVersion: config.service.version,
    });
  }

  return gatewayProfiles;
};

export const jwt = new JWT();

// NOTE: needs to await flags for loaders

export const useGatewayFlag = async () => {
  await flags;

  const useLegacy = isOn(FLAGS.USE_LEGACY_AUTH_API);
  const useApiGateway = isOn(FLAGS.USE_API_GATEWAY);

  return !(useLegacy && !useApiGateway);
};

export const isLoggedIn = async () => {
  const useGateway = await useGatewayFlag();

  return !useGateway
    ? legacy.check().catch(() => false)
    : getAuthSDK()
        .isLoggedIn()
        .catch(() => false);
};

export const getProfiles = async () => {
  return legacy.getProfiles().catch(() => []);
};

export const useAnalyticsFlag = async () => {
  await flags;

  return isOn(FLAGS.USE_ANALYTICS_SDK_AUTH);
};

export const getOnboardingSDK = (): Onboarding => {
  if (!onboarding) {
    onboarding = new Onboarding({
      targetUrl: config.onboarding.url,
      serviceName: config.service.name,
      serviceVersion: config.service.version,
    });
  }

  return onboarding;
};

export const useQuickOnboardingFlag = async () => {
  await flags;

  return isOn(FLAGS.USE_QUICK_ONBOARDING);
};
