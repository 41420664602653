import { useEffect, useState } from 'react';
import selfless from '@komi-app/selfless';
import { ProfilePage, ProfilePageProps } from '@komi-app/creator-ui';
import { Form, LoaderFunction, json, redirect } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { isLoggedIn } from '../sdks';
import { getProfilesSDK } from '../sdks';
import useFormLoading from '../hooks/form-loading';
import { getQuery } from '../hooks/query';
import useLogRocket from '../hooks/logrocket';

export type CreateProfileParams = {
  preview_id?: string;
  user_full_name?: string;
  user_handle?: string;
};

export type PreviewIdProps = {
  previewId: string | undefined;
};

export type CreateProileViewProps = Omit<
  ProfilePageProps,
  'shouldRemoveForm' | 'onSubmit'
> &
  PreviewIdProps;

export const loader: LoaderFunction = async () => {
  document.title = 'Create Profile';

  if (!(await isLoggedIn())) {
    return redirect('/login');
  }

  return json({ success: true });
};

export const useCreateProfile = () => {
  useLogRocket();

  const { user_full_name, user_handle, preview_id } =
    getQuery() as CreateProfileParams;

  const [displayName, setDisplayName] = useState(user_full_name || '');
  const [userName, setUserName] = useState(user_handle || '');
  const [errorMessage, setErrorMessage] = useState('');
  const [status, setStatus] =
    useState<ProfilePageProps['usernameInput']['status']>('IDLE');

  const { loading } = useFormLoading();

  const setErrorWithMessage = (errorMessage: string) => {
    setStatus('ERROR');
    setErrorMessage(errorMessage);
  };

  const debouncedCheckUsername = useDebouncedCallback(() => {
    getProfilesSDK()
      .getHandle(userName)
      .then(({ isExist }) => {
        if (isExist) {
          return setErrorWithMessage('Username already exists');
        }
        return setStatus('SUCCESS');
      })
      .catch(() => {
        setErrorWithMessage('There was an error checking your username');
      });
  }, 500);

  useEffect(() => {
    (async () => {
      if (!userName) {
        return setStatus('IDLE');
      }

      if (userName.length < 3) {
        return setErrorWithMessage('Username must be at least 3 characters');
      }

      // if not alphanumeric
      if (!userName.match(/^\w+$/)) {
        return setErrorWithMessage(
          'Username must only contain letters and numbers'
        );
      }

      debouncedCheckUsername();
    })();
  }, [userName]);

  const handleUsernameChange = (username: string) => {
    setStatus('LOADING');
    setUserName(username);
  };

  return {
    loading,
    onUsernameChange: handleUsernameChange,
    displayNameInput: { value: displayName },
    onDisplayNameChange: setDisplayName,
    usernameInput:
      status === 'ERROR'
        ? {
            status,
            errorMessage,
          }
        : {
            status,
            value: userName,
          },
    previewId: preview_id,
  };
};

export const CreateProfileView = ({
  usernameInput,
  onUsernameChange,
  loading,
  displayNameInput,
  onDisplayNameChange,
  previewId,
}: CreateProileViewProps) => {
  return (
    <div className="onboarding-legacy">
      <Form action="./industry">
        <ProfilePage
          shouldRemoveForm
          displayNameInput={displayNameInput}
          onDisplayNameChange={onDisplayNameChange}
          usernameInput={usernameInput}
          onUsernameChange={onUsernameChange}
          loading={loading}
        />
        <input type="hidden" name="previewId" value={previewId || ''} />
      </Form>
    </div>
  );
};

export const CreateProfile = selfless(CreateProfileView, useCreateProfile);
export default CreateProfile;

export const route = {
  path: '/create-profile',
  element: <CreateProfile />,
  loader,
};
