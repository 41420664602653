import SDK from '@komi-app/sdk';
import { Maybe, SigninBody } from '@komi-app/auth-sdk';

import {
  LegacySigninError,
  LegacySignoutError,
  LegacyCheckError,
  LegacyGetProfileError,
  LegacySigninWithGoogleError,
  LegacyForgotPasswordError,
  LegacyResetPasswordError,
  LegacySignupError,
  LegacyAcceptTeamInvite,
  LegacyAcceptAdminInvite,
  LegacyRSVPError,
  LegacyCheckIsExistedError,
} from '../errors/legacy';

import config from '../config';
import { FLAGS } from '@komi-app/flags-sdk';

export type LegacyProfile = {
  id: string;
  name: string;
  avatar?: string;
  domain: string;
};

export type SigninCheckData = {
  authenticated: boolean;
};

export type GetProfilesData = {
  profiles: LegacyProfile[];
};

export type LegacyCheckIsExistedData = {
  isExisted: boolean;
};

export type LegacySignInResponse = {
  success: boolean;
  user: LegacyUser;
};

export type LegacyUser = {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  username?: string;
  acceptTermsAndConditions?: true;
  acceptMarketingOffers?: true;
  emailVerified?: false;
  accountStatus?: string;
  activationCode?: string;
  activationCodeVersion?: number | null;
  // talentProfile?: UserProfile;
  avatar?: string;
  bio?: string;
  miniBio?: string;
  dateOfBirth?: string;
  city?: string;
  country?: string;
  gender?: string;
  phone?: string;
  roles?: [
    {
      id: number;
      name: string;
      createdAt: string;
      updatedAt: string;
    }
  ];
  isFollowing?: boolean;
  createdAt?: string;
  hasPassword?: string;
  // collaborationTalentProfiles?: User[];
  allowedMarketingEmailTalents?: {
    id: number;
  }[];
  hasBooking?: boolean;
};
export type TrackSignup = Pick<
  LegacyUser,
  'id' | 'firstName' | 'lastName' | 'email' | 'gender' | 'createdAt'
>;
export type TrackSignin = Pick<LegacyUser, 'firstName' | 'lastName' | 'id'>;

export type LegacyResetPasswordPayload = {
  email: string;
  token: string;
  newPassword: string;
};

export type LegacyForgotPasswordPayload = {
  email: string;
};

export type LegacySignupPayload = {
  fullname: string;
  email: string;
  password: string;
  googleRecaptchaToken?: string;
} & TrackedSignupParams;

export type TrackedSignupParams = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  referring_user?: string;
  referral_type?: string;
  productId?: string;
  ucc?: string;
  preview_id?: string;
  user_full_name?: string;
};

export type LegacyInvitePayload = {
  email: string;
  token: string;
};

export type UserRSVP = {
  profile?: {
    handle: string;
    fistname: string;
    lastname: string;
  };
  user: {
    id: number | null;
    accepted: boolean;
    forward?: boolean;
  };
};

export type GetRSVPData = {
  rsvp: UserRSVP;
};

export type RSVPPayload = {
  token: string;
};

export type LegacyCreateProfilePayload = {
  username: string;
  displayName: string;
  category: {
    id?: number;
    name: string;
  };
};

// TODO: tie to context provider
let currentUser: Maybe<LegacyUser>;

const setUser = (user: LegacyUser) => {
  currentUser = user;
};

export const getUser = (): Maybe<LegacyUser> => currentUser;

export class Legacy extends SDK {
  acceptAdminInvite(payload: LegacyInvitePayload) {
    return this.put('/api/users/me/admin/collaborator-invitations/accept', {
      body: payload,
    }).then(this.typedVoidResponse(LegacyAcceptAdminInvite));
  }

  async acceptTeamInvite(payload: LegacyInvitePayload): Promise<boolean> {
    const result = await this.put(
      '/api/users/me/talent-profiles/collaborator-invitations/accept',
      {
        body: payload,
      }
    ).then(this.typedResponse(LegacyAcceptTeamInvite));

    return result === true || result === 'true';
  }

  async rsvp(payload: RSVPPayload): Promise<UserRSVP> {
    const { rsvp } = await this.post('/api/talent-profiles/invitations/rsvp', {
      body: payload,
    }).then(this.typedDataResponse<GetRSVPData>(LegacyRSVPError));

    return rsvp;
  }

  createProfile(payload: LegacyCreateProfilePayload) {
    return this.post('/api/talent-profile', {
      body: payload,
    });
  }

  async signin(payload: SigninBody) {
    const { user } = await this.post('/api/auth/login', {
      body: payload,
    }).then(this.typedResponse<LegacySignInResponse>(LegacySigninError));

    setUser(user);

    return user;
  }

  async signinWithGoogle(
    token: string,
    operation?: string
  ): Promise<LegacyUser> {
    // /auth/talent-google-token/redirect
    const { user } = await this.get('/api/auth/google-token/redirect', {
      queryParams: {
        access_token: token,
        operation,
      },
    }).then(
      this.typedResponse<LegacySignInResponse>(LegacySigninWithGoogleError)
    );

    setUser(user);

    return user;
  }

  async signout() {
    await this.post('/api/auth/logout').then(
      this.typedVoidResponse(LegacySignoutError)
    );

    currentUser = void 0;
  }

  async signup(payload: LegacySignupPayload) {
    const user = await this.post('/api/auth/signup', {
      body: {
        firstName: '',
        lastName: payload.fullname,
        ...payload,
      },
      headers: {
        // forces signin on signup
        'x-feature-list': FLAGS.FEAT_SB_410_EMAIL_SIGNUP,
      },
    }).then(this.typedResponse<LegacyUser>(LegacySignupError));

    setUser(user);

    return user;
  }

  forgotPassword({ email }: LegacyForgotPasswordPayload) {
    return this.post('/api/auth/forgot-password/send', {
      body: {
        email: email.toLowerCase(),
        forgotPasswordUrl: `${config.service.url}/reset-password`,
      },
    }).then(this.typedVoidResponse(LegacyForgotPasswordError));
  }

  resetPassword(payload: LegacyResetPasswordPayload) {
    return this.post('/api/auth/forgot-password', {
      body: payload,
    }).then(this.typedVoidResponse(LegacyResetPasswordError));
  }

  async isEmailAlreadyRegistered(email: string): Promise<boolean> {
    const { isExisted } = await this.post('/api/users/check-is-existed', {
      body: {
        email,
      },
    }).then(
      this.typedResponse<LegacyCheckIsExistedData>(LegacyCheckIsExistedError)
    );

    return isExisted;
  }

  async check(): Promise<boolean> {
    const { authenticated } = await this.get('/api/auth/check').then(
      this.typedDataResponse<SigninCheckData>(LegacyCheckError)
    );

    return authenticated;
  }

  async getProfiles(): Promise<LegacyProfile[]> {
    const { profiles } = await this.get('/api/auth/profiles').then(
      this.typedDataResponse<GetProfilesData>(LegacyGetProfileError)
    );

    return profiles;
  }

  async verifyEmail(verificationCode: string) {
    return this.get(`/api/auth/activate?code=${verificationCode}`);
  }
}

export default Legacy;
