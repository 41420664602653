import { useEffect, useState } from 'react';
import { useNavigation } from 'react-router-dom';

export type FormLoading = {
  loading: boolean;
};

export const useFormLoading = (): FormLoading => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { state } = navigation;
    setLoading(state === 'loading' || state === 'submitting');
  }, [navigation]);

  return {
    loading,
  };
};

export default useFormLoading;
