import Cookies from 'js-cookie';
import { getEnvRootDomain } from './domains';
// import { getEnvRootDomain } from "./domains";

const UTM_PARAM_NAMES = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
] as const;
type UTMParamName = (typeof UTM_PARAM_NAMES)[number];
export type UTMParams = { [key in UTMParamName]?: string };

export function getUTMParams(): UTMParams | null {
  const fromSearch = getUTMParamsFromSearch();
  if (fromSearch) {
    setUTMParamsInCookies(fromSearch);
    return fromSearch;
  }

  const fromCookies = getUTMParamsFromCookies();
  return fromCookies;
}

export function copyUTMParamsFromSearchToCookies(): void {
  const fromSearch = getUTMParamsFromSearch();
  if (fromSearch) {
    setUTMParamsInCookies(fromSearch);
  }
}

function setUTMParamsInCookies(utmParams: UTMParams): void {
  for (const paramName of UTM_PARAM_NAMES) {
    const paramValue = utmParams[paramName];
    if (paramValue) {
      Cookies.set(paramName, paramValue, {
        path: '/',
        domain: getEnvRootDomain(window.location.href),
        expires: 9999,
      });
    } else {
      Cookies.remove(paramName, {
        path: '/',
        domain: getEnvRootDomain(window.location.href),
      });
    }
  }
}

function getUTMParamsFromCookies(): UTMParams | null {
  const result: UTMParams = {};

  for (const paramName of UTM_PARAM_NAMES) {
    const paramValue = Cookies.get(paramName);
    if (paramValue) {
      result[paramName] = paramValue;
    }
  }
  if (Object.keys(result).length) {
    return result;
  } else {
    return null;
  }
}

function getUTMParamsFromSearch(): UTMParams | null {
  const searchParams = new URLSearchParams(window.location.search);

  const result: UTMParams = {};
  for (const paramName of UTM_PARAM_NAMES) {
    const paramValue = searchParams.get(paramName);
    if (paramValue) {
      result[paramName] = paramValue;
    }
  }
  if (Object.keys(result).length) {
    return result;
  } else {
    return null;
  }
}
