let { komi } = window as any;

// temporary loading for tests
if (typeof process !== 'undefined' && process.env.NODE_ENV === 'test') {
  window.env = process.env as any;
}

if (!komi) {
  komi = {};

  Object.defineProperty(window, 'komi', {
    value: komi,
    configurable: false,
  });
}
//TODO: configured with env later on
const apiPrefix = 'i';
const gatewayRootUrl = `${window.env.VITE_PROTOCOL}${apiPrefix}.${window.env.VITE_DOMAIN}`;

export const config = {
  service: {
    env: window.env.VITE_NODE_ENV,
    name: window.env.VITE_NAME,
    version: window.env.VITE_VERSION,
    url: window.env.VITE_URL,
    domain: window.env.VITE_DOMAIN,
    protocol: window.env.VITE_PROTOCOL,
  },
  auth: {
    url: window.env.VITE_AUTH_URL,
    gatewayUrl: `${gatewayRootUrl}/auth`,
  },
  flags: {
    host: window.env.VITE_PUBLIC_FEATURE_FLAG_HOST,
    key: window.env.VITE_PUBLIC_FEATURE_FLAG_KEY,
    isDevToolsEnabled:
      window.env.VITE_FEATURE_FLAG_DEV_TOOLS_ENABLED === 'true',
  },
  legacy: {
    url: window.env.VITE_PUBLIC_LEGACY_URL,
  },
  logRocket: {
    id: window.env.VITE_PUBLIC_LOG_ROCKET,
  },
  profiles: {
    url: window.env.VITE_PROFILES_URL,
    gatewayUrl: `${gatewayRootUrl}/profiles`,
  },
  sales: {
    url: window.env.VITE_SALES_URL,
  },
  segment: {
    id: window.env.VITE_PUBLIC_SEGMENT_ID,
    idTalent: window.env.VITE_PUBLIC_SEGMENT_ID_TALENT,
  },
  google: {
    oauthClientId: window.env.VITE_PUBLIC_GOOGLE_OAUTH_CLIENT_ID,
    recaptchaV2SiteKey: window.env.VITE_PUBLIC_GOOGLE_RECAPTCHAV2_SITE_KEY,
  },
  cello: {
    url: window.env.VITE_PUBLIC_CELLO_ATTRIBUTION_URL,
  },
  onboarding: {
    url: `${gatewayRootUrl}/onboarding`,
  },
};

export default config;
