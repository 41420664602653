import { useEffect } from 'react';
import { useToast, ToastCreationParameters } from '@komi-app/creator-ui';

import { useActionData, useLoaderData, useLocation } from 'react-router-dom';
import { GoogleOAuthActions, Maybe } from '@komi-app/auth-sdk';
import {
  createTracker,
  AuthEvent,
  tracking,
  jwt,
  useAnalyticsFlag,
} from '../sdks';

import { identifyTalentUser } from '@komi-app/analytics-sdk';

import { TrackSignup } from '../sdks/legacy';

export type ActionToast = {
  toast: ToastCreationParameters;
};

const signUpAttempt$ = createTracker(AuthEvent.SIGN_UP_ATTEMPT);
const signup$ = createTracker(AuthEvent.SIGNUP);
const successfullLogin$ = createTracker(AuthEvent.SUCCESSFULL_LOGIN);

export const useToastData = <T extends ActionToast>(): void => {
  const { createToast } = useToast();
  const actionData = useActionData() as T;
  const loaderData = useLoaderData() as T;
  const location = useLocation();
  const toast =
    actionData?.toast || loaderData?.toast || location?.state?.toast;

  useEffect(() => {
    if (toast) {
      createToast(toast);
    }
  }, [toast]);
};

export default useToastData;

export async function urlToToast(): Promise<Maybe<ToastCreationParameters>> {
  const query = Object.fromEntries(new URLSearchParams(location.search)) || {};

  const { status, message, action, user } = query;

  if (status) {
    const success = status === 'success';
    const semantic = success ? 'success' : 'error';
    const text = success ? message : message || 'Something went wrong';
    const userPayload: TrackSignup = user ? jwt.decode(user) : {};
    const useAnalyticsSDK = await useAnalyticsFlag();

    const fullname = `${userPayload.firstName} ${userPayload.lastName}`.trim();

    useAnalyticsSDK
      ? identifyTalentUser(userPayload)
      : tracking.identifyTalentUser(userPayload);

    if (success && action === GoogleOAuthActions.SIGNIN) {
      useAnalyticsSDK
        ? successfullLogin$({
            method: 'Google',
            success: true,
            userFullname: fullname,
            userId: userPayload.id!,
          })
        : tracking.trackSignin(userPayload, 'Google');
    }

    if (action === GoogleOAuthActions.SIGNUP) {
      if (!useAnalyticsSDK) {
        tracking.trackSignupAttempt({
          email: userPayload?.email || '',
          method: 'Google',
          successful: success,
        });

        if (success) {
          tracking.trackSignup(userPayload);
        }
      } else {
        signUpAttempt$(
          {
            method: 'Google',
            success: true,
          },
          { source: 'talent' }
        ); // FIXME: update source name

        if (success) {
          signup$({
            email: userPayload.email!,
            userFullname: fullname,
            userId: userPayload.id!,
            createdAt: userPayload.createdAt,
          });
        }
      }
    }

    if (text) {
      return {
        text,
        semantic,
        durationMs: 10000,
      };
    }
  }
}
