import { SignupPage, SignupOption, Semantic } from '@komi-app/creator-ui';
import { FaGoogle } from 'react-icons/fa';
import { MdMailOutline } from 'react-icons/md';
import selfless from '@komi-app/selfless';
import { useNavigate } from 'react-router-dom';

import { FLAGS, useFeatureIsOn } from '@komi-app/flags-sdk';

import config from '../config';
import { GoogleOAuthActions } from '@komi-app/auth-sdk';
import { getAuthSDK, isLoggedIn, tracking } from '../sdks';

import { redirect, LoaderFunction } from 'react-router-dom';
import useToastData, { urlToToast } from '../hooks/toast-data';
import useLogRocket from '../hooks/logrocket';
import { useSignupQuery } from '../hooks/signup-query';
import { useShouldHideGoogleOptionBasedOnUserAgent } from '../hooks/google-option';
import { addToDataLayer } from '@komi-app/analytics-sdk';

export const loader: LoaderFunction = async () => {
  document.title = 'KOMI | Sign up';

  const [toast, loggedIn] = await Promise.all([
    // tracking handled in toast fn
    urlToToast(),
    isLoggedIn(),
  ]);

  if (loggedIn) {
    return redirect('/profiles');
  }

  return {
    toast,
  };
};

const talentTermsLink = `${config.service.url}/talent-terms`;
const privacyPolicyLink = `${config.service.url}/privacy-policy`;

export interface SignupViewProps {
  options: SignupOption[];
}

export const path = '/signup';

export const useSignup = () => {
  useLogRocket();
  useToastData();
  const { firstQuery, lastQuery, query } = useSignupQuery();

  const navigate = useNavigate();
  const useGoogleSignup = useFeatureIsOn(FLAGS.GOOGLE_LOGIN_SIGNUP_INVITE);
  const useAnalyticsSDK = useFeatureIsOn(FLAGS.USE_ANALYTICS_SDK_AUTH);
  const shouldHideGoogleOption = useShouldHideGoogleOptionBasedOnUserAgent(
    navigator.userAgent
  );

  const onClickSignUpWithEmail = () => {
    const emailSignUpURL = `${path}/email${firstQuery}`;

    navigate(emailSignUpURL);
  };

  const options: SignupOption[] = [
    {
      label: 'Sign up with Email',
      icon: <MdMailOutline size={24} data-testid="mail-icon" />,
      onClick: onClickSignUpWithEmail,
    },
  ];

  if (useGoogleSignup && !shouldHideGoogleOption) {
    options.unshift({
      label: 'Sign up with Google',
      icon: <FaGoogle size={18} data-testid="google-icon" />,
      semantic: Semantic.PRIMARY,
      onClick: async () => {
        useAnalyticsSDK
          ? addToDataLayer('trial-signup-google')
          : tracking.addToDataLayer('trial-signup-google');

        const payload = {
          successRedirectUrl: `${config.service.url}/signup?action=${GoogleOAuthActions.SIGNUP}&method=google&status=success${lastQuery}`,
          failureRedirectUrl: `${config.service.url}/signup?action=${GoogleOAuthActions.SIGNUP}&method=google&status=failure${lastQuery}`,
          action: GoogleOAuthActions.SIGNUP,
          tracking: query,
        };

        const { url } = await getAuthSDK().signupWithGoogle(payload);

        window.location.href = url;
      },
    });
  }

  return {
    options,
  };
};

export const SignupView = ({ options }: SignupViewProps) => (
  <SignupPage
    signinUrl="/signin"
    privacyPolicyLink={privacyPolicyLink}
    talentTermsLink={talentTermsLink}
    options={options}
  />
);

export const Signup = selfless(SignupView, useSignup);
export default Signup;

export const route = {
  path,
  element: <Signup />,
  loader,
};

export const alias = {
  path: '/trial',
  loader: () => redirect(path),
};
