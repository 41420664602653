import { createContext, useState, useEffect, useContext } from 'react';
import { FLAGS, useFeatureIsOn } from '@komi-app/flags-sdk';
export interface KetchPurposes {
  analytics: boolean;
  behavioral_advertising: boolean;
  essential_services: boolean;
}

const KetchContext = createContext<KetchPurposes | undefined>(undefined);

// If the flag is off, we can default to `true`. This mimics the original way that
// cookies were loaded - that is, there was no cookie acceptance logic. :D

export const KetchProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const defaultKetchPurpose = !useFeatureIsOn(FLAGS.KETCH);
  const defaultKetchPurposes = {
    analytics: defaultKetchPurpose,
    behavioral_advertising: defaultKetchPurpose,
    essential_services: defaultKetchPurpose,
  };

  const [ketch, setKetch] = useState<KetchPurposes | undefined>(
    defaultKetchPurposes
  );

  useEffect(() => {
    (window as any)?.semaphore?.push([
      'onConsent',
      function (consent: any) {
        setKetch(consent.purposes);
      },
    ]);
  }, [setKetch]);
  return (
    <KetchContext.Provider value={ketch}>{children}</KetchContext.Provider>
  );
};

export const useKetchPurposes = (): KetchPurposes => {
  const ketch = useContext(KetchContext);
  if (ketch === undefined) throw new Error('KetchContext not found');
  return ketch;
};
