import selfless from '@komi-app/selfless';
import When from '@komi-app/when';

import {
  LoaderFunction,
  useLoaderData,
  Form,
  ActionFunction,
} from 'react-router-dom';

import {
  SigninOption,
  SigninPage,
  SignupEmailPage,
  ValidationProcessor,
} from '@komi-app/creator-ui';

import { getAuthSDK, legacy } from '../sdks';
import useQuery, { getQuery } from '../hooks/query';

import config, { flags } from '../config';
import { LegacySignupPayload, UserRSVP } from '../sdks/legacy';
import {
  useEmailProcessor,
  useFullnameProcessor,
  useFullnameWithHtmlTagValidationProcessor,
  usePasswordProcessor,
} from '../hooks/processors';
import { FLAGS, isOn } from '@komi-app/flags-sdk';
import { SigninBody } from '@komi-app/auth-sdk';
import useToastData from '../hooks/toast-data';
import useFormLoading from '../hooks/form-loading';
import { SOMETHING_WENT_WRONG } from '@komi-app/profiles-sdk';
import useLogRocket from '../hooks/logrocket';
import useSigninOptions from '../hooks/signin-options';
import { setDirtyProfileCookie } from './Profiles';

type LegacyInvitationParams = {
  email?: string;
  token?: string;
  newUser?: string;
  talentProfileId?: string;
  username?: string;
};

type LoaderData = {
  rsvp?: UserRSVP;
  disabled?: boolean;
};

// dirty fix for legacy links with +
const fixEmail = (email?: string) => (email && email.replace(/ /g, '+')) || '';

const goToProfile = (handle: string, profileId: string) => {
  const { protocol } = location;
  const port = protocol === 'http:' ? ':2000' : '';
  const target = `${protocol}//${handle}.${config.service.domain}${port}/admin`;

  setDirtyProfileCookie(profileId);

  location.href = target;

  return null;
};

export const loader: LoaderFunction = async () => {
  try {
    document.title = 'KOMI | Team invitation';

    await flags; // NOTE: to support flags in action function

    let { email } = getQuery();
    const { token, username, talentProfileId } = getQuery();
    email = fixEmail(email);

    const rsvp = await legacy.rsvp({ token });
    const { profile, user } = rsvp;

    if (!profile) return (location.href = '/404');

    if (user.accepted && user.forward) {
      return goToProfile(username, talentProfileId);
    }

    if (user.id && !user.accepted) {
      await legacy.acceptTeamInvite({ token, email });
    }

    return { rsvp };
  } catch (e: any) {
    const message = e?.message || 'Something went wrong';
    const text = `${message} | Please retry later.`;

    return {
      disabled: true,
      toast: {
        text,
        semantic: 'error',
        durationMs: 60000,
      },
    };
  }
};

export const action: ActionFunction = async ({ request }) => {
  try {
    const formData = await request.formData();
    const intent = formData.get('intent'); // TODO: type intent
    const payload = Object.fromEntries(formData);
    const useLegacy = isOn(FLAGS.USE_LEGACY_AUTH_API);
    const useApiGateway = isOn(FLAGS.USE_API_GATEWAY);
    const useGateway = !(useLegacy && !useApiGateway);
    const { username, talentProfileId } = getQuery();
    const auth = getAuthSDK();

    if (intent === 'signup') {
      const { email, fullname, password } = payload as LegacySignupPayload;

      await legacy.signup({
        email,
        fullname,
        password,
      });

      const { token } = getQuery();

      await legacy.acceptTeamInvite({ token, email });

      if (useGateway) {
        await auth.signin({
          email,
          password,
        });
      }

      return goToProfile(username, talentProfileId);
    }

    if (intent === 'signin') {
      const { email, password } = payload as SigninBody;

      const signin = useLegacy
        ? (payload: SigninBody) => legacy.signin(payload)
        : (payload: SigninBody) => auth.signin(payload);

      await signin({
        email,
        password,
      });

      return goToProfile(username, talentProfileId);
    }
  } catch (e: any) {
    let message = e?.message || SOMETHING_WENT_WRONG;

    return {
      disabled: true,
      toast: {
        text: message,
        semantic: 'error',
        durationMs: 60000,
      },
    };
  }
};

export type InvitationViewProps = {
  isNewUser: boolean;
  email: string;
  title: string;
  forgotPasswordUrl: string;
  fullnameProcessor: ValidationProcessor;
  emailProcessor: ValidationProcessor;
  passwordProcessor: ValidationProcessor;
  disabled?: boolean;
  loading: boolean;
  signinOptions: SigninOption[];
};

export const useInvitation = (): InvitationViewProps => {
  useLogRocket();

  const { query } = useQuery<LegacyInvitationParams>();
  const { rsvp, disabled } = useLoaderData() as LoaderData;
  const { loading } = useFormLoading();

  useToastData();

  const user = rsvp?.user;
  let { email } = query;

  const fullnameProcessor = useFullnameProcessor();
  const fullnameWithHtmlTagValidatorProcessor =
    useFullnameWithHtmlTagValidationProcessor();
  const emailProcessor = useEmailProcessor();
  const passwordProcessor = usePasswordProcessor();
  const signinOptions = useSigninOptions(email);
  const useHtmlTagValidator = isOn(FLAGS.FIX_SB_170_EMAIL_SANITISATION);

  const isNewUser = !user?.id;

  email = fixEmail(email);

  const title = isNewUser
    ? 'Sign up to accept your invitation'
    : user.accepted
    ? 'Sign in to continue'
    : 'Sign in to accept your invitation';

  const forgotPasswordUrl = '/forgot-password';

  return {
    title,
    loading,
    disabled,
    isNewUser,
    email,
    forgotPasswordUrl,
    fullnameProcessor: useHtmlTagValidator
      ? fullnameWithHtmlTagValidatorProcessor
      : fullnameProcessor,
    emailProcessor,
    passwordProcessor,
    signinOptions,
  };
};

export const InvitationView = ({
  isNewUser,
  disabled,
  email,
  loading,
  title,
  forgotPasswordUrl,
  emailProcessor,
  fullnameProcessor,
  passwordProcessor,
  signinOptions,
}: InvitationViewProps) => (
  <Form method="POST">
    <When
      value={isNewUser}
      then={
        <SignupEmailPage
          title={title}
          email={email}
          disabled={disabled}
          loading={loading}
          shouldRemoveForm
          passwordProcessor={passwordProcessor}
          emailProcessor={emailProcessor}
          fullnameProcessor={fullnameProcessor}
        />
      }
      otherwise={
        <SigninPage
          invite
          title={title}
          email={email}
          options={signinOptions}
          disabled={disabled}
          loading={loading}
          shouldRemoveForm
          emailProcessor={emailProcessor}
          forgotPasswordUrl={forgotPasswordUrl}
        />
      }
    />
  </Form>
);

export const Invitation = selfless(InvitationView, useInvitation);
export default Invitation;

export const route = {
  path: '/invitation',
  element: <Invitation />,
  loader,
  action,
};
