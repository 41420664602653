import { useCallback } from 'react';

export const passwordHelper =
  'Minimum 8 characters, including uppercase and lowercase letters and numbers';

export const useFullnameProcessor = () =>
  useCallback((value: string) => {
    if (!value || !value.length) return 'Enter your name';

    if (value.length > 40) return 'Maximum 40 characters';

    return '';
  }, []);

export const useFullnameWithHtmlTagValidationProcessor = () =>
  useCallback((value: string) => {
    if (!value || !value.length) return 'Enter your name';

    if (value.length > 40) return 'Maximum 40 characters';

    const urlRegex = /(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.:-]*)/g;

    //Prevent html tags & urls to be added as full name
    if (value.includes('<') || value.includes('>') || urlRegex.test(value))
      return 'Invalid name';

    return '';
  }, []);

export const useEmailProcessor = () =>
  useCallback(async (value: string) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const isValidFormat = value && emailRegex.test(value);

    if (!isValidFormat) {
      return 'Invalid email address';
    }

    return '';
  }, []);

export const usePasswordProcessor = () =>
  useCallback((value: string) => {
    if (!value || !value.length) return passwordHelper;

    if (value.length < 8) return 'Password must be at least 8 characters long';

    const numberRegex = /[0-9]/;
    if (!numberRegex.test(value))
      return 'Password must contain at least 1 number';

    const uppercaseRegex = /[A-Z]/;
    if (!uppercaseRegex.test(value))
      return 'Password must contain at least 1 capital letter';

    const lowercaseRegex = /[a-z]/;
    if (!lowercaseRegex.test(value))
      return 'Password must contain at least 1 lowercase letter';

    return '';
  }, []);
