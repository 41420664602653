import {
  IndustryOption,
  IndustryPage,
  IndustryPageProps,
} from '@komi-app/creator-ui';
import selfless from '@komi-app/selfless';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import {
  ActionFunction,
  json,
  LoaderFunction,
  redirect,
  useNavigation,
  useSubmit,
} from 'react-router-dom';
import {
  getAuthSDK,
  legacy,
  tracking,
  createTracker,
  AuthEvent,
  useQuickOnboardingFlag,
  getGatewayProfilesSDK,
} from '../../sdks';
import config from '../../config';
import useLogRocket from '../../hooks/logrocket';
import { FLAGS, isOn } from '@komi-app/flags-sdk';
import { setDirtyProfileCookie } from '../Profiles';
// import { RegionCode } from '@komi-app/profiles-sdk';
import When from '@komi-app/when';

import {
  MdOutlineMusicNote,
  MdOutlineDiamond,
  MdOutlineSpa,
  MdOutlineShoppingCart,
  MdWorkOutline,
  MdOutlinePodcasts,
  MdOutlineSchool,
  MdOutlineSportsEsports,
  MdOutlineTheaterComedy,
  MdStarOutline,
} from 'react-icons/md';

const DISPLAY_NAME = 'displayName';
const USER_NAME = 'username';
const PREVIEW_ID = 'previewId';
const INDUSTRY_NAME = 'industry';
const industries = {
  Business: 66,
  Charity: 65,
  Entertainment: 68,
  'Fashion & Beauty': 9,
  'Food & Drink': 2,
  'Gaming & E-Sports': 64,
  'Influencer / Creator': 1,
  Journalism: 69,
  'Lifestyle & Wellness': 4,
  Music: 6,
  Photography: 8,
  Sport: 3,
  Travel: 86,
  Other: 151,
};

type IndustryMap = IndustryOption & {
  id: number;
};

const updatedIndustries: IndustryMap[] = [
  {
    id: 6,
    key: 'Music',
    label: 'Music',
    icon: <MdOutlineMusicNote size={24} />,
  },
  {
    id: 1015,
    key: 'Lifestyle, Beauty & Fashion',
    label: 'Lifestyle, Beauty & Fashion',
    icon: <MdOutlineDiamond size={24} />,
  },
  {
    id: 1016,
    key: 'Health, Fitness & Wellness',
    label: 'Health, Fitness & Wellness',
    icon: <MdOutlineSpa size={24} />,
  },
  {
    id: 1017,
    key: 'E-commerce',
    label: 'E-commerce',
    icon: <MdOutlineShoppingCart size={24} />,
  },
  {
    id: 1018,
    key: 'Entrepreneurship',
    label: 'Entrepreneurship',
    icon: <MdWorkOutline size={24} />,
  },
  {
    id: 134,
    key: 'Podcast',
    label: 'Podcast',
    icon: <MdOutlinePodcasts size={24} />,
  },
  {
    id: 179,
    key: 'Coaching',
    label: 'Coaching',
    icon: <MdOutlineSchool size={24} />,
  },
  {
    id: 277,
    key: 'Gaming',
    label: 'Gaming',
    icon: <MdOutlineSportsEsports size={24} />,
  },
  {
    id: 480,
    key: 'Comedy',
    label: 'Comedy',
    icon: <MdOutlineTheaterComedy size={24} />,
  },
  {
    id: 151,
    key: 'Other',
    label: 'Other',
    icon: <MdStarOutline size={24} />,
  },
];

const profileCreationAttempt$ = createTracker(
  AuthEvent.PROFILE_CREATION_ATTEMPT
);

/*
 Retrieves the id of given industry
*/
function getIndustryId(industryKey: string): number | undefined {
  return updatedIndustries.find((ind) => ind.key === industryKey)?.id;
}

export type IndustryViewProps = {
  industries: IndustryPageProps['industries'];
  selectedIndustry: IndustryPageProps['selectedKey'];
  setSelectedIndustry: IndustryPageProps['onSelect'];
  onSubmit: IndustryPageProps['onSubmit'];
  loading: IndustryPageProps['loading'];
  isCategoryFFOn?: IndustryPageProps['isCategoryFFOn'];
  title?: IndustryPageProps['title'];
  subtitle?: IndustryPageProps['subtitle'];
};

export const loader: LoaderFunction = ({ request }) => {
  const params = new URL(request.url).searchParams;
  const userName = params.get(USER_NAME);
  const displayName = params.get(DISPLAY_NAME);

  if (!userName || !displayName) {
    return redirect('/create-profile');
  }
  if (userName.length < 3) {
    return redirect('/create-profile');
  }
  // if not alphanumeric
  if (!userName.match(/^\w+$/)) {
    return redirect('/create-profile');
  }

  return json({
    success: true,
    data: {
      username: userName,
      displayname: displayName,
    },
  });
};

export const action: ActionFunction = async ({ request }) => {
  const params = new URL(request.url).searchParams;
  const userName = params.get(USER_NAME);
  const displayName = params.get(DISPLAY_NAME);
  const previewId = params.get(PREVIEW_ID);
  const useLegacy = isOn(FLAGS.USE_LEGACY_AUTH_API);
  const useApiGateway = isOn(FLAGS.USE_API_GATEWAY);
  const useGateway = !(useLegacy && !useApiGateway);
  const isQuickOnboardingOn = await useQuickOnboardingFlag();

  const isNewCategoryFFOn = isOn(
    FLAGS.FEAT_SB_525_UPDATE_ONBOARDING_CATEGORIES
  );
  const useAnalyticsSDK = isOn(FLAGS.USE_ANALYTICS_SDK_AUTH);

  let successful = false;
  let redirection = request.url;

  if (!userName || !displayName) {
    return redirect('/create-profile');
  }
  if (userName.length < 3) {
    return redirect('/create-profile');
  }
  // if not alphanumeric
  if (!userName.match(/^\w+$/)) {
    return redirect('/create-profile');
  }

  let industry = '';
  let profileId;

  try {
    const formData = await request.formData();
    const rawIndustryName = formData.get(INDUSTRY_NAME);

    if (!rawIndustryName) {
      // should never come here
      return redirect(request.url);
    }

    industry = rawIndustryName.toString();

    // TODO: FF

    if (isQuickOnboardingOn && previewId) {
      await getGatewayProfilesSDK().claimPreviewProfile({
        previewId,
        handle: userName,
        displayName,
      });
      profileId = previewId;
    } else {
      // legacy cookie need renewal on profile creation
      const profileResponse = await legacy
        .createProfile({
          category: isNewCategoryFFOn
            ? {
                name: industry,
                id: getIndustryId(industry),
              }
            : {
                name: industry,
                id: industries[industry as keyof typeof industries],
              },
          displayName,
          username: userName,
        })
        .then((response) => response.json());

      profileId = profileResponse?.user?.talentProfile?.id;
    }

    setDirtyProfileCookie(profileId);

    // refresh uam cookie
    if (useGateway) {
      const auth = getAuthSDK();

      auth.init(profileId);
      await auth.refresh();
    }

    const handle = userName;

    // NOTE: requires UAM as it handles cookie renewal on page access

    // const { handle } = await profiles.create({
    //   // @ts-expect-error: let ts know that industryName isn't just a string
    //   category: { name: industryName, id: industries[industryName] },
    //   displayName,
    //   handle: userName,
    //   createdVia: config.service.name,

    //   // currency is allegedly legacy and not being used
    //   // TODO: check if the above is still the case
    //   currency: 'usd',
    //   // TODO: make country code optional and auto-grab from ip in backend
    //   countryCode: RegionCode.US,
    // });

    const { protocol, domain } = config.service;
    const port = protocol === 'http://' ? ':2000' : '';
    if (isQuickOnboardingOn && previewId) {
      redirection = `${protocol}${handle}.${domain}${port}/admin`;
    } else {
      // return redirect(`${protocol}${handle}.${domain}${port}/admin`);
      redirection = `${protocol}${handle}.${domain}${port}/admin/onboarding/social-links`;
    }
  } catch (error) {
    // TODO: add error to param
    // return redirect(request.url);
  }

  useAnalyticsSDK
    ? profileCreationAttempt$({
        success: successful,
        industry,
        isMobile,
        isMobileOnboardingExperimentEnabled: true,
      })
    : tracking.trackProfileCreationAttempt({
        successful,
        industry,
        isMobile,
        isMobileOnboardingExperimentEnabled: true,
      });

  return redirect(redirection);
};

export const useCreateProfileIndustry = (): IndustryViewProps => {
  useLogRocket();
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const submit = useSubmit();
  const navigation = useNavigation();

  const isSubmitting = navigation.state === 'submitting';
  const isRedirecting =
    navigation.state === 'loading' &&
    navigation.formData != null &&
    navigation.formAction !== navigation.location.pathname;

  const handleSubmit = () => {
    submit({ [INDUSTRY_NAME]: selectedIndustry }, { method: 'POST' });
  };
  const isNewCategoryFFOn = isOn(
    FLAGS.FEAT_SB_525_UPDATE_ONBOARDING_CATEGORIES
  );

  return {
    industries: isNewCategoryFFOn
      ? updatedIndustries
      : Object.keys(industries).map((indust) => ({
          key: indust,
          label: indust,
        })),
    selectedIndustry,
    setSelectedIndustry,
    onSubmit: handleSubmit,
    loading: isSubmitting || isRedirecting,
    isCategoryFFOn: isNewCategoryFFOn,
    title: 'Describe your mini-site',
    subtitle:
      'Choose one that best describes you for a personalized KOMI experience.',
  };
};

export const CreateProfileIndustryView = ({
  industries,
  selectedIndustry,
  setSelectedIndustry,
  onSubmit,
  loading,
  isCategoryFFOn,
  title,
  subtitle,
}: IndustryViewProps) => {
  return (
    <div className="onboarding-legacy">
      <When
        value={isCategoryFFOn}
        then={
          <IndustryPage
            industries={industries}
            selectedKey={selectedIndustry}
            onSelect={setSelectedIndustry}
            onSubmit={onSubmit}
            loading={loading}
            isCategoryFFOn={isCategoryFFOn}
            title={title}
            subtitle={subtitle}
          />
        }
        otherwise={
          <IndustryPage
            industries={industries}
            selectedKey={selectedIndustry}
            onSelect={setSelectedIndustry}
            onSubmit={onSubmit}
            loading={loading}
          />
        }
      />
    </div>
  );
};

export const CreateProfileIndustry = selfless(
  CreateProfileIndustryView,
  useCreateProfileIndustry
);
export default CreateProfileIndustry;

export const route = {
  path: '/create-profile/industry',
  element: <CreateProfileIndustry />,
  loader,
  action,
};
