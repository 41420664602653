import {
  Form,
  ActionFunction,
  redirect,
  LoaderFunction,
} from 'react-router-dom';

import {
  ResetPasswordPage,
  ToastCreationParameters,
} from '@komi-app/creator-ui';

import selfless from '@komi-app/selfless';
import { legacy, isLoggedIn } from '../sdks';

import { usePasswordProcessor } from '../hooks/processors';
import useFormLoading from '../hooks/form-loading';
import useToastData from '../hooks/toast-data';
import useRedirectData from '../hooks/redirect-data';
import useQuery from '../hooks/query';

import { LegacyResetPasswordPayload } from '../sdks/legacy';
import { LegacyResetPasswordError } from '../errors/legacy';
import useLogRocket from '../hooks/logrocket';

export interface ResetPasswordViewProps {
  loading: boolean;
  email: string;
  token: string;
  passwordProcessor: (value: string) => void;
}

export type ActionData = {
  toast: ToastCreationParameters;
  notFound: boolean;
};

export const loader: LoaderFunction = async () => {
  document.title = 'KOMI | Reset password';

  if (await isLoggedIn()) {
    return redirect('/profiles');
  }

  return null;
};

export const action: ActionFunction = async ({ request }) => {
  const reset = (payload: LegacyResetPasswordPayload) => legacy.resetPassword(payload);

  try {
    const { token, password } = Object.fromEntries(
      await request.formData()
    ) as Record<string, string>;
    const payload = {
      token,
      newPassword: password,
      email: new URLSearchParams(location.search).get('email') as string,
    };

    await reset(payload);

    return {
      redirect: {
        path: '/signin',
        state: {
          toast: {
            text: 'Your password has been successfully reset.',
            semantic: 'success',
            durationMs: 10000,
          },
        },
      },
    };
  } catch (e) {
    let message = 'Something went wrong';

    if (e instanceof LegacyResetPasswordError) {
      const payload = await e.response.json();

      if (typeof payload.message === 'string') message = payload.message;
    }

    return {
      toast: {
        text: message,
        semantic: 'error',
        durationMs: 5000,
      },
    };
  }
};

export const useResetPassword = (): ResetPasswordViewProps => {
  useLogRocket();
  useToastData();
  useRedirectData();

  const { loading } = useFormLoading();
  const { query } = useQuery();
  const passwordProcessor = usePasswordProcessor();

  const { email, token } = query;

  return {
    loading,
    email,
    token,
    passwordProcessor,
  };
};

export const ResetPasswordView = ({
  email,
  token,
  loading,
  passwordProcessor,
}: ResetPasswordViewProps) => (
  <Form method="POST">
    <ResetPasswordPage
      shouldRemoveForm
      passwordProcessor={passwordProcessor}
      email={email}
      token={token}
      loading={loading}
    />
  </Form>
);

export const ResetPassword = selfless(ResetPasswordView, useResetPassword);
export default ResetPassword;

export const route = {
  path: '/reset-password',
  element: <ResetPassword />,
  loader,
  action,
};
