import config from './config';

import { Context, init, FEATURE_LIST_LOCAL_STORAGE } from '@komi-app/flags-sdk';

const domain =
  typeof window !== 'undefined' ? window.location.hostname : void 0;
const url = typeof window !== 'undefined' ? window.location.href : void 0;
const featureList =
  typeof localStorage !== 'undefined'
    ? localStorage.getItem(FEATURE_LIST_LOCAL_STORAGE)?.split(',')
    : void 0;

export const context: Context = {
  apiHost: config.flags.host,
  clientKey: config.flags.key,
  enableDevMode: config.flags.isDevToolsEnabled,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.variationId,
    });
  },
  attributes: {
    domain,
    featureList,
    url,
  },
};

/*
  NOTE: 
      You cannot access React context from loaders and actions
      because they are, by design, decoupled from the
      render cycle.
  
  cf. https://github.com/remix-run/react-router/discussions/9286

  This is exported so that loaders can refer to it if called
  before the flag provider is ready.
*/

export default init(context);
