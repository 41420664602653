import { ResponseError } from '@komi-app/typed-response';

export class LegacySigninError extends ResponseError {
  // TODO: support error codes, etc...
}

export class LegacySigninWithGoogleError extends ResponseError {
  // TODO: support error codes, etc...
}

export class LegacySignoutError extends ResponseError {
  // TODO: support error codes, etc...
}

export class LegacyRSVPError extends ResponseError {}
export class LegacyAcceptTeamInvite extends ResponseError {}
export class LegacyAcceptAdminInvite extends ResponseError {}
export class LegacySignupError extends ResponseError {}
export class LegacyCheckError extends ResponseError {}
export class LegacyForgotPasswordError extends ResponseError {}
export class LegacyResetPasswordError extends ResponseError {}
export class LegacyCheckIsExistedError extends ResponseError {}
export class LegacyGetProfileError extends ResponseError {}
