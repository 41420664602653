import { LoaderFunction, redirect } from 'react-router-dom';
// import config from '../config';
import { getAuthSDK, legacy } from '../sdks';
import { FLAGS, isOn } from '@komi-app/flags-sdk';
import { flags } from '../config';

export const loader: LoaderFunction = async () => {
  document.title = 'KOMI | Sign out';

  await flags;
  const useApiGateway = isOn(FLAGS.USE_API_GATEWAY);

  useApiGateway ? await getAuthSDK().signout() : await legacy.signout();

  return redirect('/signin');
};

export const route = {
  path: '/signout',
  loader,
};
