/*
  We define URL parts as follows. For an example URL,
    https://a.b.c.komi.io:443/talent/file.html

  We have the following parts:
    protocol:    `https`
    port:        `443`
    tld:         `io`
    subdomains:  `[ 'a', 'b', 'c' ]`
    domainName:  `komi`
    rootDomain:  `komi.io`
    domainParts: `[ 'a', 'b', 'c', 'komi', 'io' ]`
    filename:    `file.html`
    pathname:    `/talent/file.html`
    hostname:    `a.b.c.komi.io`
    host:        `a.b.c.komi.io:443`
    origin:      `https://a.b.c.komi.io:443`
    url:         `https://a.b.c.komi.io:443/talent/file.html`

  These conform as much as possible to `window.location`
*/

const defaultDomainNames = ['komi', 'localhost'];
const defaultDomainEnvs = [
  'local',
  'develop',
  'staging',
  'preprod',
  'production',
];

/**
 * Gets the index of the last matching domain name of a URL
 *
 * @param {string} url The URL from which to get the root domain
 * @param {string[]} domainNames The domain names to check against
 * @returns The index of the last matching domain name
 */
const getLastDomainNameIndex = (
  url: string,
  domainNames: string[] = defaultDomainNames
): number => {
  const domainParts = getDomainParts(url);

  let lastDomainNameIndex = -1;
  for (let i = 0; i < domainParts.length; i++) {
    const domainPart = domainParts[i];
    if (domainNames.includes(domainPart)) {
      lastDomainNameIndex = i;
    }
  }

  return lastDomainNameIndex;
};

/**
 * Gets the domain parts of a URL
 *
 * @param {string} url The URL from which to get the domain parts
 * @returns The domain parts of the URL
 */
export const getDomainParts = (url: string): string[] => {
  const { hostname } = new URL(url);
  return hostname.split('.');
};

/**
 * Gets the root domain of a URL
 *
 * @param {string} url The URL from which to get the root domain
 * @param {string[]} domainNames The domain names to check against
 * @returns The root domain of the URL
 */
export const getRootDomain = (
  url: string,
  domainNames: string[] = defaultDomainNames
): string => {
  // Find the last occurence of a domain part that matches a member of `domainNames`.
  // Given that the TLD can vary in part length, and there's simply too many to list,
  //   the idea is that we should find the last occurrence of any domain names in the
  //   domain name list, and any part thereafter makes up the TLD
  const domainNameIndex = getLastDomainNameIndex(url, domainNames);
  if (domainNameIndex === -1) {
    throw new Error(`URL '${url}' does not contain a known domain name`);
  }

  const domainParts = getDomainParts(url);
  const rootDomainParts = domainParts.slice(domainNameIndex);

  return rootDomainParts.join('.');
};

/**
 * Gets the root domain of a URL including the optional environment subdomain
 *
 * @param {string} url The URL from which to get the root domai
 * @param {string[]} domainNames The domain names to check againstn
 * @param {string[]} domainEnvs The domain environments to check against
 * @returns The root domain of the URL with the
 */
export const getEnvRootDomain = (
  url: string,
  domainNames: string[] = defaultDomainNames,
  domainEnvs: string[] = defaultDomainEnvs
): string => {
  // Find the last occurence of a domain part that matches a member of `domainNames`.
  // Given that the TLD can vary in part length, and there's simply too many to list,
  //   the idea is that we should find the last occurrence of any domain names in the
  //   domain name list, and any part thereafter makes up the TLD
  const domainNameIndex = getLastDomainNameIndex(url, domainNames);
  if (domainNameIndex === -1) {
    throw new Error(`URL '${url}' does not contain a known domain name`);
  }

  const domainParts = getDomainParts(url);
  const domainEnvIndex = domainNameIndex - 1;
  const isDomainEnvPresent =
    domainEnvIndex > -1 && domainEnvs.includes(domainParts[domainEnvIndex]);

  const rootDomainParts = domainParts.slice(
    isDomainEnvPresent ? domainEnvIndex : domainNameIndex
  );

  return rootDomainParts.join('.');
};

/**
 * Gets the root domain of a URL
 *
 * @param url The URL from which to get the root domain
 * @param {string[]} domainNames The domain names to check against
 * @returns The root domain of the URL
 */
export const getRootDomainWithPort = (
  url: string,
  domainNames: string[] = defaultDomainNames
): string => {
  const { port } = new URL(url);
  const portAppendage = port.length === 0 ? '' : `:${port}`;

  return `${getRootDomain(url, domainNames)}${portAppendage}`;
};
